import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row,Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableVerification from "./dataTable/TableVerification";
import {useGetSourceVerificationsQuery,
  useCreateSourceVerificationMutation,
  useUpdateSourceVerificationMutation, useDeleteSourceVerificationMutation } from "../../redux/slice/verificationApiSlice";
  import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
  import Swal from "sweetalert2";
  import { getUserInfo } from '../../redux/slice/userSlice';
  import { useSelector } from "react-redux";

export default function VerificationOR() {

  // modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const init = {
    lib_source: '',
}
const [form, setForm] = useState({...init})

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  const {data: typeSource = [], isLoading: loadTypeObligations} = useGetSourceVerificationsQuery()

  const [createData, {isLoading: loadCreate}] = useCreateSourceVerificationMutation()
  const [updateData, {isLoading: loadUpdate}] = useUpdateSourceVerificationMutation()
  const [deleteData, {isLoading: loadDelete}] = useDeleteSourceVerificationMutation()
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const changeForm = (field, value) => setForm({...form, [field]: value})

  const save = async() => {
 
    try {

        if (form?.id_source) {
         
            await updateData(form).unwrap()
            setForm({...init})
            Swal.fire({
              title: "SUCCES!",
              text: "Action réussie",
              icon: "success"
            });
        } else {

       
         
            await createData(form).unwrap()
            setForm({...init})
            Swal.fire({
              title: "SUCCES!",
              text: "Action réussie",
              icon: "success"
            });
        }
     
        
    } catch (error) {
  
        Swal.fire({
          icon: "ERREUR",
          title: "Oops...",
          text: error.data.errors[0].message,
        });
    }
}

const deleteVerification = async (id) => {

  try {
    // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)

    // Affichez une confirmation à l'utilisateur avant la suppression réelle (en utilisant une bibliothèque comme Swal)
    const confirmResult = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!"
    });

    if (confirmResult.isConfirmed) {

      // Supprimez réellement la catégorie
     const retour =  await deleteData(id).unwrap();

      // Affichez une notification de succès
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie!",
        // text: "Source "+ retour.data.lib_source +" supprimée!",
        icon: "success"
      });
    }
  } catch (error) {
    console.error("Erreur lors de la suppression :", error);

    // Affichez une notification d'erreur
    Swal.fire({
      icon: "ERREUR",
      title: "Oops...",
      text: error.data.message,
      
    });
  } finally {
    // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
  }
};

  switchSkin(skin);
const createLog = async ()=>{
  try {
  
    await createLogPage({content: "Page de creation des sources de vérification d'obligation réglémentaire"}).unwrap();  
    // Swal.fire({
    //   title: "Log accomplie!",
    //   text: "Action réussie",
    //   icon: "success",
    // });
} catch (error) {
 
  console.log('error', error)
}
}
const user = useSelector(getUserInfo);
useEffect(() => {
  createLog()   
}, []);

  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><NavLink to="/dashboard-obligation-reglmt" >Obligations Règlementaires</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">Sources de vérification</li>
            </ol>
            <h4 className="main-title mb-0">Gestion des sources de vérification</h4>
          </div>

        </div>


        <div className="container_liste_form">

<div className="first_container">
<span className="titre_cat">Liste des sources de vérification</span>

<hr style={{width: '100%'}} />

<TableVerification
deleteVerification={deleteVerification}
typeSource={typeSource}
setForm={setForm}
/>

</div>





<div className="second_container">
<span className="titre_cat">Nouvelle source</span>

<hr style={{width: '100%'}} />

<div className="container_form">

<div className="container_border">


<div className="sous_container_form">

 

  <div className="container_label">
  <Form.Label htmlFor="exampleFormControlInput1">
             Source :
            </Form.Label>
  </div>
  <div style={{width:'70%'}}>
  <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="source"
              onChange={e => changeForm('lib_source', e.target.value)}
              value={form.lib_source}
              
            />
  </div>
           
</div>

<div className="container_btn">
  <Button  variant="primary" onClick={()=>setForm({...init})}>
                  Annuler
                </Button>

                <Button  variant="danger" onClick={()=>save()}>
                  Enregister
                </Button>
  </div>

  </div>
</div>



</div>

    
</div>
         
                       
                       {/* ----------------Modal de modification----------- */}
                       <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                  <Modal.Title>Modification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Control type="text" id="exampleFormControlInput1" placeholder="Entrer catégorie" />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Annuler
                  </Button>
                  <Button variant="success" onClick={handleClose}>
                   Valider
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* ----------------modal de modification---------- */}
                       <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4> Etês-vous sûr de vouloir supprimer ?</h4>
                   </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose1}>
                    Annuler
                  </Button>
                  <Button variant="danger" onClick={handleClose1}>
                    Valider
                  </Button>
                </Modal.Footer>
              </Modal>

                        <Footer />
                      </div>
                    </React.Fragment>
                    )
}