import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import Sidebar from "../layouts/Sidebar";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";
import { useGetPlaintesQuery } from "../redux/apiSlice/plainteApiSlice.js";
import DashCards from "./DashCards.js";
import { Skeleton } from "@mui/material";
import "./styleDash.css";
import moment from "moment";
import {
  useGetListeIspcQuery,
  useGetListeMncQuery,
  useGetUssdQuery,
} from "../redux/apiSlice/statistiquePlainteApiSlice.js";
import { useCreateLogPageMutation } from "../redux/apiSlice/logPageApiSlice.js";
import TableClassification from "../pages/statistique/obligation/dataTables/TableClassification.js";
import ShieldIcon from "@rsuite/icons/Shield";
import PageIcon from "@rsuite/icons/Page";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import DeviceIcon from "@rsuite/icons/Device";
import TextImageIcon from "@rsuite/icons/TextImage";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line, Pie, Doughnut, Bar, HorizontalBar } from "react-chartjs-2";
import {
  useGetAllRessourcesQuery,
  useGetTrancheQuery,
} from "../redux/apiSlice/numerotationApiSlice.js";
export default function DashboardNumerotation() {
  // graphe
  const { data: liste_ussd = [], isLoading: loadListe_ussd } =
    useGetUssdQuery();
  const { data: liste_ispc = [], isLoading: loadListe_ispc } =
    useGetListeIspcQuery();
  const { data: liste_mnc = [], isLoading: loadListe_mnc } =
    useGetListeMncQuery();
  const { data: ressources = [], isLoading: loadRessources } =
    useGetAllRessourcesQuery();
  const { data: tranches = [] } = useGetTrancheQuery();

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user || !user.id) {
      window.location.href = "/";
    }
  }, [user]);

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Dashboard numérotation" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const repereIdTrancheEtFiltreEnFonctionDesStatut = (
    lib_tranche,
    id_statut
  ) => {
    const table_id = tranches
      .filter((tranch) =>
        [parseInt(lib_tranche)].includes(parseInt(tranch.lib_tranche))
      )
      .map((tranch) => tranch.id_tranche);
    return ressources.filter(
      (n) => n.id_stat_num === id_statut && n.tranche.id_tranche === table_id[0]
    ).length;
  };

  const recupereNbreEltAyantStatut = (arr, value) => {
    return arr.filter((n) => n.id_stat_num === value).length;
  };

  const { id: idStatut, lib: libelles } = {
    id: [1, 2, 3, 4, 5, 6],
    lib: [
      "Libre",
      "En instance",
      "Réservé",
      "Attribué",
      "Bloqué",
      "Inutilisable",
    ],
  };

  const mncData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_mnc, id)
  );
  const ussdData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_ussd, id)
  );
  const ispcData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_ispc, id)
  );

  const optionsUssd = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  const dataUssd = {
    labels: libelles,
    datasets: [
      {
        label: "USSD",
        data: ussdData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
      },
    ],
  };

  const optionsIspc = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  const dataIspc = {
    labels: libelles,
    datasets: [
      {
        label: "ISPC",
        data: ispcData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
      },
    ],
  };

  const optionsMnc = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  const dataMnc = {
    labels: libelles,
    datasets: [
      {
        label: "Mnc",
        data: mncData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],

        borderColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const optionsPnm = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const dataPnm = {
    labels: [
      "tranche 1",
      "tranche 2",
      "tranche 3",
      "tranche 4",
      "tranche 5",
      "tranche 6",
      "tranche 7",
      "tranche 8",
      "tranche 9",
    ],
    datasets: idStatut.map((id, index) => {
      const lib_tranche = libelles[id - 1];
      let backgroundColor = "";
      let borderColor = "";

      switch (lib_tranche) {
        case "Libre":
          backgroundColor = "rgb(75, 192, 192)";
          borderColor = "rgb(75, 192, 192)";
          break;
        case "En instance":
          backgroundColor = "rgb(54, 162, 235)";
          borderColor = "rgb(54, 162, 235)";
          break;
        case "Réservé":
          backgroundColor = "rgb(153, 102, 255)";
          borderColor = "rgb(153, 102, 255)";
          break;
        case "Attribué":
          backgroundColor = "rgb(255, 205, 86)";
          borderColor = "rgb(255, 205, 86)";
          break;
        case "Bloqué":
          backgroundColor = "rgb(255, 99, 132)";
          borderColor = "rgb(255, 99, 132)";
          break;
        case "Inutilisable":
          backgroundColor = "rgb(199, 199, 199)";
          borderColor = "rgb(199, 199, 199)";
          break;
        default:
          backgroundColor = "rgb(0, 0, 0)";
          borderColor = "rgb(0, 0, 0)";
      }

      return {
        label: lib_tranche,
        data: Array.from({ length: 9 }, (_, i) => {
          return repereIdTrancheEtFiltreEnFonctionDesStatut(
            (i + 1).toString(),
            id
          );
        }),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      };
    }),
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-2">
        <div className="d-md-flex flex-column">
          <h4 className="main-title mb-0">Tableau de Bord</h4>
        </div>

        <Row className="container_graphique">
          {loadListe_ussd && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_ussd && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_ussd">
                <span className="titre_compatiment">USSD</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_ussd">
                <Pie options={optionsUssd} data={dataUssd} />
              </div>
            </Col>
          )}

          {loadListe_ispc && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_ispc && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_ispc">
                <span className="titre_compatiment">ISPC</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_ispc">
                <Doughnut options={optionsIspc} data={dataIspc} />
              </div>
            </Col>
          )}
        </Row>
        <Row className="mb-2">
          {loadListe_mnc && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_mnc && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_mnc">
                <span className="titre_compatiment">MNC</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_mnc">
                <Bar options={optionsMnc} data={dataMnc} />
              </div>
            </Col>
          )}

          {loadRessources && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadRessources && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_pnm">
                <span className="titre_compatiment">PNN</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_pnm">
                <Bar options={optionsPnm} data={dataPnm} />
              </div>
            </Col>
          )}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
