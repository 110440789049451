import { apiSlice } from "../api/apiSlice"
import moment from "moment"

export const plainteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        archivePlainte: builder.mutation({
            query: body => ({
                url: `/plainte/archive/${body.id}`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Plaintes', id: arg.id_plainte }],
        }),

        updatePlainte: builder.mutation({
            query: body => ({
                url: `/plainte/update_auth/${body.id_plt}`,
                method: "POST",
                body
            }),
            transformResponse: response => response.data,
            invalidatesTags: (result, error, arg) => [{ type: 'Plaintes', id: arg.id_plt }],
        }),
        updateObsPlainte: builder.mutation({
            query: body => ({
                url: `/plainte/up_obs_invest/${body.id_obs}`,
                method: "PUT",
                body
            }),
            transformResponse: response => response.data,
            invalidatesTags: (result, error, arg) => [{ type: 'Plaintes', id: arg.id_plt }],
        }),

        createPlainte: builder.mutation({
            query: body => ({
                url: '/plainte/create_auth',
                method: "POST",
                body
            }),
            invalidatesTags: ['Plaintes']
        }),

        createPlainteExcel: builder.mutation({
            query: body => ({
                url: 'plainte/create_through_excel',
                method: "POST",
                body
            }),
            invalidatesTags: ['Plaintes']
        }),

        createObsPlainte: builder.mutation({
            query: body => ({
                url: '/plainte/add_obs_invest',
                method: "POST",
                body
            }),
            invalidatesTags: ['ObservationsPlaintes']
        }),

        createResObservation: builder.mutation({
            query: body => ({
                url: '/plainte/add_res_invest',
                method: "POST",
                body
            }),
            invalidatesTags: ['ResultatInvestigation']
        }),

        createNotificationStepChanging: builder.mutation({
            query: body => ({
                url: '/plainte/step_changing_notification',
                method: "POST",
                body
            }),
            invalidatesTags: ['Notifications']
        }),

        getPlaintes: builder.query({
            query: () => ({
                url: '/plainte',
                method: "GET",
            }),
            transformResponse: response => {
                const days = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
                const hebdomadaire = [];
                const currentDate = moment()
                for (let i = 4; i >= 0; i--) {
                    const date = moment(currentDate);
                    date.add(-i, 'days')
                    const day = date.format("YYYY-MM-DD")
                    const liste = response.filter(item => moment(item.createdAt).format("YYYY-MM-DD") === day)
                    hebdomadaire.push({
                        show: i < 4,
                        day,
                        labelDays: days[date.day()],
                        liste,
                    });
                }

                const plaintes = {
                    liste: response,
                    hebdomadaire
                }

                localStorage.setItem('plaintes', JSON.stringify({ liste: plaintes }))

                return plaintes
            },
            providesTags: (result) => {
                return result ? [
                    { type: 'Plaintes', id: 'LIST' },
                    ...result.liste.map(item => ({ type: 'Plaintes', id: item.id_plt }))
                ] : ['Plaintes']
            }
        }),

        getPlaintesTypeAccuse: builder.query({
            query: () => ({
                url: '/plainte',
                method: "GET",
            }),
            transformResponse: response => {

                const liste = response.filter(item => item.id_stat_plt === 2)

                return {
                    liste: liste,

                }
            },
            providesTags: (result) => {
                return result ? [
                    { type: 'Plaintes', id: 'LIST' },
                    ...result.liste.map(item => ({ type: 'Plaintes', id: item.id_plt }))
                ] : ['Plaintes']
            }
        }),

// services 
        getServices: builder.query({
            query: () => ({
                url: '/plainte/service',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'sercices-plaintes', id: 'LIST' },
                    ...result.map(item => ({ type: 'sercices-plaintes', id: item.id_service }))
                ] : ['sercices-plaintes']
            }
        }),

        getDirections: builder.query({
            query: () => ({
                url: '/obligation/direction',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'directions-plaintes', id: 'LIST' },
                    ...result.map(item => ({ type: 'directions-plaintes', id: item.id_direction }))
                ] : ['directions-plaintes']
            }
        }),

        createServices: builder.mutation({
            query: body => ({
                url: '/plainte/service/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['sercices-plaintes']
        }),
        updateServices: builder.mutation({

            query: (body) => ({
                url: `/plainte/service/update/${body.id_service}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ['sercices-plaintes']
        }),

          
        deleteServices: builder.mutation({
           
            query: (id) => ({
              url: `plainte/service/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['sercices-plaintes'],
          }),

// --------------------------------
// catégorie de plaintes 

        getTypePlaintes: builder.query({
            query: () => ({
                url: '/plainte/type_plainte',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'TypePlaintes', id: 'LIST' },
                    ...result.map(item => ({ type: 'TypePlaintes', id: item.id_type_plt }))
                ] : ['TypePlaintes']
            }
        }),

        createTypePlainte: builder.mutation({
            query: body => ({
                url: '/plainte/type_plainte/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['TypePlaintes']
        }),

        updateTypePlainte: builder.mutation({

            query: (body) => ({
                url: `/plainte/type_plainte/update/${body.id_type_plt}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ['TypePlaintes']
        }),

          
        deleteTypePlainte: builder.mutation({
           
            query: (id) => ({
              url: `plainte/type_plainte/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['TypePlaintes'],
          }),
// -------------------------------------
        getObsPlaintes: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_observation_plainte/${id_plt}`,
                method: "GET",
            }),
            providesTags: (result, error, id_plt) => {
                return [{ type: 'ObservationPlainte', id: id_plt }];
            }
        }),

        getResultatObs: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_result_observation_plainte/${id_plt}`,
                method: "GET",
            }),
            providesTags: ['ResultatInvestigation']
        }),

        getBackInvest: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_back_invest/${id_plt}`,
                method: "GET",
            }),
            providesTags: ['BackInvestigation']
        }),
        getBackInst: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_back_inst/${id_plt}`,
                method: "GET",
            }),
            providesTags: ['BackInstruction']
        }),

        getStatutPlaintes: builder.query({
            query: () => ({
                url: '/plainte/statut_plainte',
                method: "GET",
            }),
            transformResponse: response => response.data,
            providesTags: (result) => {
                return result ? [
                    { type: 'StatutPlaintes', id: 'LIST' },
                    ...result.map(item => ({ type: 'StatutPlaintes', id: item.id_stat_plt }))
                ] : ['StatutPlaintes']
            }
        }),

        getHistoriquePlainte: builder.query({
            query: id_plt => ({
                url: `/plainte/get_action/${id_plt}`,
                method: "GET",
            }),
        }),

        updatePlainteStatut: builder.mutation({
            query: body => ({
                url: `/plainte/change_state`,
                method: "PUT",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Plaintes', id: arg.id_plt }],
        }),

        getObservation: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_observation_plainte/${id_plt}`,
                method: "GET",
            }),
          providesTags: (result) => {
    return result ? [
        { type: 'ObservationPlainte', id: 'LIST' },
        { type: 'ObservationPlainte', id: result.id_obs }
    ] : ['ObservationPlainte'];
}

        }),
        getCompletude: builder.query({
            query: (id_plt) => ({
                url: `/plainte/get_completude/${id_plt}`,
                method: "GET",
            }),
//           providesTags: (result) => {
//     return result ? [
//         { type: 'completudePlainte', id: 'LIST' },
//         { type: 'completudePlainte', id: result.id_obs }
//     ] : ['completudePlainte'];
// }

        }),
        updateResultatInvesPlainte: builder.mutation({
            query: (body) => ({
                url: `/plainte/up_res_invest/${body.id}`,
                method: "POST",
                body :body.formData
            }),
            invalidatesTags: ['ResultatInvestigation'],
        }),

        createInstPlainte: builder.mutation({
            query: body => ({
                url: '/plainte/change_state_plainte_inst',
                method: "POST",
                body
            }),
            invalidatesTags: ['Plaintes']
        }),

        returnInvesPlainte: builder.mutation({
            query: body => ({
                url: '/plainte/return_back_invest',
                method: "POST",
                body
            }),
            invalidatesTags: ['Plaintes']
        }),

        returnToInstPlainte: builder.mutation({
            query: body => ({
                url: '/plainte/return_back_instruction_comite',
                method: "POST",
                body
            }),
            invalidatesTags: ['Plaintes']
        }),
        // getDecisionPlainte: builder.query({
        //     query: () => ({
        //         url: '/plainte/decision',
        //         method: "GET",
        //     }),
        // }),
        // getVerdicteParPlainte: builder.query({
        //     query: (body) => ({
        //         url: `/plainte/verdict/${body.id_plt}`,
        //         method: "GET",
        //     }),
        // }),
// ------------------Avis conseil---------------------------------
        getAvisConseilPlainte: builder.query({
            query: (body) => ({
                url:`/plainte/cloture_avis/${body.id_plt}`,
                method: "GET",
            }),

            providesTags: (result) => {
                return result ? [
                    { type: 'avis', id: 'LIST' },
                    { type: 'avis', id: result.id_avis }
                ] : ['avisConseil'];
            }
        }),
        createClotureAvis: builder.mutation({
            query: body => ({
                url: '/plainte/cloture_avis/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['avisConseil']
        }),
       
        // ------------verdict--------------------------------
        getVerdicteParPlainte: builder.query({
            query: (body) => ({
                url:`/plainte/verdict/${body.id_plt}`,
                method: "GET",
            }),

            providesTags: (result) => {
                return result ? [
                    { type: 'verdict', id: 'LIST' },
                    { type: 'verdict', id: result.id }
                ] : ['Verdicte'];
            }
        }),
        createVerdicte: builder.mutation({
            query: body => ({
                url: '/plainte/verdict/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['Verdicte']
        }),
        updateVerdicte: builder.mutation({

            query: (body) => ({
                url: `/plainte/verdict/update/${body.id}`,
                method: "POST",
                body : body.formData
            }),
            invalidatesTags: ['Verdicte']
        }),

        getVerdictePieces: builder.query({
            query: (body) => ({
                url:`/plainte/verdict/pieces/${body.id_plt}`,
                method: "GET",
            }),
        }),

        // action plainte 
        createAction: builder.mutation({
            query: body => ({
                url: '/plainte/decision/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['Action_plainte']
        }),
        updateAction: builder.mutation({

            query: (body) => ({
                url: `/plainte/decision/update/${body.id_dec}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ['Action_plainte']
        }),

          
        deleteAction: builder.mutation({
           
            query: (id) => ({
              url: `plainte/decision/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Action_plainte'],
          }),

          getDecisionPlainte: builder.query({
            query: () => ({
                url: '/plainte/decision',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Action_plainte', id: 'LIST' },
                    { type: 'Action_plainte', id: result.id_dec }
                ] : ['Action_plainte'];
            }
            
        }),

        // ----------notify_plainte---------------

        getNotifyPlainte: builder.query({
            query: (id_stat_plt) => ({
                url: `/plainte/get_plainte_notify/${id_stat_plt}`,
                method: "GET",
            }),
            providesTags: ['Notify_plainte']
        }),

        updateNotifyPlainte: builder.mutation({

            query: (id_stat_plt) => ({
                url: `/plainte/set_notify_read/${id_stat_plt}`,
                method: "PUT",
            }),
            invalidatesTags: ['Notify_plainte']
        }),
        // ----------avis cloture---------------

        createAvis: builder.mutation({
            query: body => ({
                url: '/plainte/cloture_avis/create',
                method: "POST",
                body
            }),
            invalidatesTags: ['Avis_plainte']
        }),

        // --------------------------------
// type de requête

getTypeRequete: builder.query({
    query: () => ({
        url: '/plainte/type_requete',
        method: "GET",
    }),
    providesTags: (result) => {
        return result ? [
            { type: 'TypeRequete', id: 'LIST' },
            ...result.map(item => ({ type: 'TypeRequete', id: item.id_type_req }))
        ] : ['TypeRequete']
    }
}),

createTypeRequete: builder.mutation({
    query: body => ({
        url: '/plainte/type_requete/create',
        method: "POST",
        body
    }),
    invalidatesTags: ['TypeRequete']
}),

updateTypeRequete: builder.mutation({

    query: (body) => ({
        url: `/plainte/type_requete/update/${body.id_type_req}`,
        method: "PUT",
        body
    }),
    invalidatesTags: ['TypeRequete']
}),

  
deleteTypeRequete: builder.mutation({
   
    query: (id) => ({
      url: `plainte/type_requete/del/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['TypeRequete'],
  }),

//   -------------------canaux de réception--------------
  getCanauxReception: builder.query({
    query: (body) => ({
      url: "/plainte/canal_plainte/",
      method: "GET",
     }),
    }),
      
// ------------Problématique plainte-------------------
getProblematique: builder.query({
    query: () => ({
        url: '/plainte/problematique',
        method: "GET",
    }),
    providesTags: (result) => {
        return result ? [
            { type: 'Problematique', id: 'LIST' },
            ...result.map(item => ({ type: 'TypeRequete', id: item.id_prod }))
        ] : ['Problematique']
    }
}),

createProblematique: builder.mutation({
    query: body => ({
        url: '/plainte/problematique/create',
        method: "POST",
        body
    }),
    invalidatesTags: ['Problematique']
}),

updateProblematique: builder.mutation({

    query: (body) => ({
        url: `/plainte/problematique/update/${body.id}`,
        method: "PUT",
        body
    }),
    invalidatesTags: ['Problematique']
}),

  
deleteProblematique: builder.mutation({
   
    query: (id) => ({
      url: `plainte/problematique/del/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['TypeRequete'],
  }),

//   -------------------Solution attendues--------------------
getSolution: builder.query({
    query: () => ({
        url: '/plainte/solution',
        method: "GET",
    }),
    providesTags: (result) => {
        return result ? [
            { type: 'Solution', id: 'LIST' },
            ...result.map(item => ({ type: 'solution', id: item.id_solution }))
        ] : ['Solution']
    }
}),
getSolutionAll: builder.query({
    query: () => ({
        url: '/plainte/solution/all',
        method: "GET",
    }),
    providesTags: (result) => {
        return result ? [
            { type: 'Solution', id: 'LIST' },
            ...result.map(item => ({ type: 'solution', id: item.id_solution }))
        ] : ['Solution']
    }
}),
getSolutionOther: builder.query({
    query: () => ({
        url: '/plainte/solution/other',
        method: "GET",
    }),
    providesTags: (result) => {
        return result ? [
            { type: 'Solution', id: 'LIST' },
            ...result.map(item => ({ type: 'solution', id: item.id_solution }))
        ] : ['Solution']
    }
}),
createSolution: builder.mutation({
    query: body => ({
        url: '/plainte/solution/create',
        method: "POST",
        body
    }),
    invalidatesTags: ['Solution']
}),

updateSolution: builder.mutation({

    query: (body) => ({
        url: `/plainte/solution/update/${body.id}`,
        method: "PUT",
        body
    }),
    invalidatesTags: ['Solution']
}),

  
deleteSolution: builder.mutation({
   
    query: (id) => ({
      url: `plainte/solution/del/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['TypeRequete'],
  }),




    })
})

export const {
    useGetHistoriquePlainteQuery,
    useGetPlaintesQuery,
    useGetObservationQuery,
    useGetCompletudeQuery,

    useGetTypePlaintesQuery,
    useCreateTypePlainteMutation,
    useUpdateTypePlainteMutation,
    useDeleteTypePlainteMutation,


    useGetObsPlaintesQuery,
    useGetResultatObsQuery,
    useGetBackInvestQuery,
    useGetBackInstQuery,

    useGetServicesQuery,
    useCreateServicesMutation,
    useUpdateServicesMutation,
    useDeleteServicesMutation,

    useGetStatutPlaintesQuery,
    useCreateObsPlainteMutation,
    useCreateResObservationMutation,
  
    useCreateNotificationStepChangingMutation,
    useCreatePlainteMutation,
    useCreatePlainteExcelMutation,
    useUpdatePlainteMutation,
    useUpdatePlainteStatutMutation,
    useArchivePlainteMutation,
    useUpdateObsPlainteMutation,
    useUpdateResultatInvesPlainteMutation,
    useCreateInstPlainteMutation,
    useReturnInvesPlainteMutation,
    useReturnToInstPlainteMutation,
 
    useCreateClotureAvisMutation,
    useGetAvisConseilPlainteQuery,
    
    useCreateVerdicteMutation,
    useUpdateVerdicteMutation,
    useGetVerdicteParPlainteQuery,
    useGetVerdictePiecesQuery,


    useCreateActionMutation,
    useUpdateActionMutation,
    useDeleteActionMutation,
    useGetDecisionPlainteQuery,
    useGetDirectionsQuery,

    useGetNotifyPlainteQuery,
    useUpdateNotifyPlainteMutation,

    useCreateAvisMutation,

    useGetTypeRequeteQuery,
    useCreateTypeRequeteMutation,
    useUpdateTypeRequeteMutation,
    useDeleteTypeRequeteMutation,

    useGetCanauxReceptionQuery,

    useGetProblematiqueQuery,
    useCreateProblematiqueMutation,
    useUpdateProblematiqueMutation,
    useDeleteProblematiqueMutation,

    useGetSolutionQuery,
    useGetSolutionAllQuery,
    useGetSolutionOtherQuery,
    useCreateSolutionMutation,
    useUpdateSolutionMutation,
    useDeleteSolutionMutation,

} = plainteApiSlice