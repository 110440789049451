import { apiSlice } from "../api/apiSlice"

export const verificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSourceVerifications: builder.query({
            query: () => ({
                url: '/obligation/source',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'SourceVerification', id: 'LIST' },
                    ...result.map(item => ({ type: 'SourceVerification', id: item.id_source }))
                ] : ['SourceVerification']
            }
        }),

        createSourceVerification: builder.mutation({
            query: body => ({
                url: '/obligation/source/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['SourceVerification']
        }),

        updateSourceVerification: builder.mutation({
            query: body => ({
                url: `/obligation/source/update/${body.id_source}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['SourceVerification']
        }),
        deleteSourceVerification: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/source/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['SourceVerification'],
          }),
    })
})

export const {
    useGetSourceVerificationsQuery,
    useCreateSourceVerificationMutation,
    useUpdateSourceVerificationMutation,
    useDeleteSourceVerificationMutation,
} = verificationApiSlice