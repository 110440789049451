import { apiSlice } from "../api/apiSlice"

export const echeanceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEcheances: builder.query({
            query: () => ({
                url: '/obligation/echeance',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Echeances', id: 'LIST' },
                    ...result.map(item => ({ type: 'Echeances', id: item.id_echeance }))
                ] : ['Echeances']
            }
        }),

        createEcheance: builder.mutation({
            query: body => ({
                url: '/obligation/echeance/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Echeances']
        }),

        updateEcheance: builder.mutation({
            query: body => ({
                url: `/obligation/echeance/update/${body.id_echeance}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Echeances']
        }),

        deleteEcheance: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/echeance/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Echeances'],
          }),
    })
})

export const {
    useGetEcheancesQuery,
    useCreateEcheanceMutation,
    useUpdateEcheanceMutation,
    useDeleteEcheanceMutation,
    
} = echeanceApiSlice