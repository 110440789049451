import { apiSlice } from "../api/apiSlice"

export const loginApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: body => ({
                url: '/login',
                method: "POST",
                body,
            }),
        }),
        verifyCode: builder.mutation({
            query: body => ({
                url: '/login/verify_2fa',
                method: "POST",
                body,
            }),
        })
    })
})

export const {
    useLoginMutation,
    useVerifyCodeMutation
} = loginApiSlice