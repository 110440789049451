import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal, Table } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableProfile from "./TableProfile";
import Swal from "sweetalert2";
import Select from "react-select";
import "./styleProfile.css";
import {
  useGetEcheancesQuery,
  useCreateEcheanceMutation,
  useUpdateEcheanceMutation,
  useDeleteEcheanceMutation,
} from "../../redux/slice/echeanceApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { useGetModulesQuery } from "../../redux/apiSlice/moduleApiSlice";
import { useGetFonctionnaliteQuery } from "../../redux/apiSlice/fonctionnalitésApiSlice";
import { useCreateProfilMutation, useGetProfilQuery, useDeleteProfilMutation } from "../../redux/apiSlice/profileApiSlice";
import frCH from "date-fns/locale/fr-CH";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";

export default function ListeProfile() {
  const navigate = useNavigate()
  const { data: modules = [], isLoading: loadModules } = useGetModulesQuery();
  const { data: fonctionnalite = [], isLoading: loadFonc } =
    useGetFonctionnaliteQuery();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();


  const [modulesPage, setModulesPage] = useState([]);
  const [modulesPage2, setModulesPage2] = useState([]);
  const [modulesTemoin, setModulesTemoin] = useState([]);
  


  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const init = {
    lib_echeance: "",
  };
  const [form, setForm] = useState({ ...init });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const { data: profiles = [], isLoading: loadProfile } =
  useGetProfilQuery();

  const [createData, { isLoading: loadCreate }] = useCreateProfilMutation();

  const [updateData, { isLoading: loadUpdate }] = useUpdateEcheanceMutation();
  const [deleteData, { isLoading: loadDelete }] = useDeleteProfilMutation();

  const [etatAll, setEtatAll] = useState(false);
  const [nomProfil, setNomProfil] = useState();
  const [fonc, setFonc] = useState([]);
  const [fonc2, setFonc2] = useState([]);
  const [temoinFonc, setTemoinFonc] = useState([]);
  const [etatFonc, setEtatFonc] = useState(0);



  const changeForm = (field, value) => setForm({ ...form, [field]: value });

  const createProfil = async () => {

    if (nomProfil == undefined) {
         Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Veuillez saisir le nom du profil",
      });
    
      
    }else {
      const retr = modulesPage.map((item)=>{

       const tab = item.fonc.filter((fc)=>fc.value == true)
        
        return{
              ...item, fonc : tab
        }
  
      })

      const final = retr.map((item)=>{

        const monObj = item.fonc.map((fc)=>(fc.id_fonc))
            
        return {
          module : item.id_mod,
          fonc : monObj
        }
      })
  
    const  data = {
        nom_profil : nomProfil,
        mod_insert : final
      }

  
      try {
          await createData(data).unwrap();
          setForm({ ...init });
          Swal.fire({
            title: "SUCCES!",
            text: "Action réussie",
            icon: "success",
          });
        
          setNomProfil()
          modulesPage(modulesTemoin)
      } catch (error) {
   
        // console.log('error', error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.data.errors[0].message,
        });
      }
    }

    
  };

  const deleteEcheance = async (id) => {

    const confirmResult = await Swal.fire({
      title: "CONFIRMATION",
      text: "Voulez vous supprimer ce profil ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    });
    if (confirmResult.isConfirmed) {
    try {

        // Supprimez réellement la catégorie
        const retour = await deleteData(id).unwrap();

        // Affichez une notification de succès
        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success",
        });
     
    } catch (error) {

console.log("error", error)
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Veuillez réessayer",
       
      });
    } finally {
      
    }

  }
  };

  const handleChange = (id_fonc, value) => {
     
    switch (id_fonc) {
      case "tous":
        setEtatAll(value)
        const objVal = fonc2.map((item)=>{
           return{
             ...item, value : value
           }    
 
       })

       setFonc(objVal)
       setFonc2(objVal)

        break;
    
      default:

      const objtrv = fonc2.map((item)=>{

         if (item.id_fonc === id_fonc) {
          return{
            ...item, value : value
          }
         } else {
          return{
            ...item
          }
         }

      })

setFonc(objtrv)
setFonc2(objtrv)

        break;
    }
  };

const loadData = ()=>{

  setNomProfil()
  setFonc([])
  setEtatFonc(1)
}
 

  const chargeFonc = (id_mod)=>{

     setEtatFonc(1)
     setEtatAll(false)
    const resul = temoinFonc.filter((item)=>(item.id_mod ==id_mod ))
   
    setFonc(resul)
    setFonc2(resul)

  }

  const ajoutMod = ()=>{

   const aa =  modulesPage.map((item)=>{

    const tab =  item.fonc.map((fc)=>{
        const rsl = fonc.find((elt)=>(elt.id_fonc == fc.id_fonc))
  
    if (rsl) {
      return {
        ...fc,
        value: rsl.value
      };
    }else
        return  fc
      })

      return  {
        ...item,
        fonc : tab
      }
    })

    setEtatFonc(0)
   setFonc([])
    setModulesPage(aa)
    setModulesPage2(aa)

  }

  const retraitFonc = (id_mod, id_fonc)=>{
    
    const rtr = modulesPage2.map((item)=>{

      if(item.id_mod == id_mod){
        
        const rsul =  item.fonc.map((fc)=>{
            if(fc.id_fonc == id_fonc){
            return{...fc, value : false}
            }else{
              return fc
            }
          })
      return {...item, fonc : rsul}

        }else{
return item
        }
    })

setModulesPage(rtr)
setModulesPage2(rtr)
    
        }

   

  switchSkin(skin);
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de liste des profils"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  } 
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    const tabMod = modules.map((item) => {
      return {
        id_mod: item.id_mod,
        lib_mod: item.lib_mod,
        fonc: item.Fonctionnalites.map((fonc) => {
          return {
            id_fonc: fonc.id_fonc,
            lib_fonc: fonc.lib_fonc,
            id_mod: fonc.id_mod,
            value : false
          };
        }),
      };
    });

   
    setModulesPage(tabMod);
    setModulesTemoin(tabMod)
    const tabRetour = fonctionnalite.map((item) => {
      return {
        ...item, value : false
      };
    });

    setTemoinFonc(tabRetour);
    switchSkin(skin);
  }, [skin, modules, fonctionnalite]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/creation-profil">
                Création de profil
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste de profil
              </li>
            </ol>      
          </div>
        </div>

        <div className="container row">
          <div className="col">

        
            <span className="titre_cat">Liste des profils</span>
          

          


            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form_profile">
          
                {/* <i className="ri-close-circle-fill" style={{color :'red'}}></i> */}
     <Table className="mb-0">
  <thead>
    <tr>
      <th scope="col">Nom profil</th>
      <th scope="col">Fonctionnalités</th>
      <th scope="col">Actions</th>

    </tr>
  </thead>
  <tbody>
    {profiles.map((item, index) => (
      <tr key={index}>
        <td scope="row">{item.nom_profil}</td>
        <td scope="row">
          {item.mod_insert.map((fc, index) => {   
            const rtv = modules.find((mod)=>(mod.id_mod == fc.module))
              if (rtv) {
                const verif = fc?.fonc.length > 0 
 
                if (verif) {

                  return (
                    <div key={index}>
                       <span className="text-success">{rtv.lib_mod}</span>
                       {fc.fonc.map((vc, index)=>{
                        const obj = fonctionnalite.find((tc)=>(tc.id_fonc == vc))
                        if (obj) {
                          return(
                            <div key={index}>
                            <ul >
                             <li>{obj.lib_fonc}</li> 
                            </ul>
                            </div>
                          )
                        }

                       })}
                    </div>
                  )
                  
                } else {
                  return(
                    <div>
                           {""}
                    </div>
                  )
                }

              } 
})}
        </td>
        <td><i class="ri-delete-bin-6-line  ri-2x" onClick={()=>deleteEcheance(item.id_profil)} style={{color: 'red', cursor: 'pointer'}}></i></td>
      </tr>
    ))}
  </tbody>
</Table>

   
                                             
                </div>

              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
