import { apiSlice } from "../api/apiSlice"

export const tempEmailPlainteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTempEmailPlainte: builder.query({
            query: () => ({
                url: '/settings/template_mail_plainte',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'TempEmailPlainte', id: 'LIST' },
                    ...result.map(item => ({ type: 'TempEmailPlainte', id: item.id_echeance }))
                ] : ['TempEmailPlainte']
            }
        }),

        createTempEmailPlainte: builder.mutation({
            query: body => ({
                url: '/settings/template_mail_plainte/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['TempEmailPlainte']
        }),

        updateTempEmailPlainte: builder.mutation({
            query: body => ({
                url: `/settings/template_mail_plainte/update/${body.id_template}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['TempEmailPlainte']
        }),

        deleteTempEmailPlainte: builder.mutation({
           
            query: (id) => ({
              url: `/settings/template_mail_plainte/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['TempEmailPlainte'],
          }),
    })
})

export const {
    useGetTempEmailPlainteQuery,
    useCreateTempEmailPlainteMutation,
    useUpdateTempEmailPlainteMutation,
    useDeleteTempEmailPlainteMutation,
    
} = tempEmailPlainteApiSlice