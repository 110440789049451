import React, { Fragment, useEffect, useState } from "react";

import { Button as Btn, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import { Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";
import {
  useCreateServiceTrancheMutation,
  useDeleteServiceTrancheMutation,
  useGetServiceTrancheQuery,
  useUpdateServiceTrancheMutation,
} from "../../redux/apiSlice/numerotationApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";

const ServiceTranche = () => {
  const { data: serviceTab, isLoading } = useGetServiceTrancheQuery();
  const [createServiceTranche] = useCreateServiceTrancheMutation();
  const [updateServiceTranche] = useUpdateServiceTrancheMutation();
  const [deleteServiceTranche] = useDeleteServiceTrancheMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const fileName = "service-tranches";
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const cols = [
    { field: "num", header: "N°", sortable: true },
    { field: "lib_service", header: "Service", sortable: true },
    { field: "actions", header: "Actions", sortable: false },
  ];
  const colsExport = [
    { field: "num", header: "N°", sortable: true },
    { field: "lib_service", header: "Service", sortable: true },
  ];
  const exportColumns = colsExport.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const initForm = { lib_service: "" };

  const [skin, setSkin] = useState(currentSkin);
  const [search, setSearch] = useState("");
  const [form, setForm] = useState({ ...initForm });

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de creation des services de tranche",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  const disabled = form.lib_service === "";

  const reset = () => setForm({ ...initForm });

  const save = async () => {
    Swal.fire({
      title: "Gestion des services",
      text: "Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        if (form?.id_service) {
          await updateServiceTranche(form).unwrap();
        } else {
          await createServiceTranche(form).unwrap();
        }

        Swal.fire({
          title: "Félicitations!",
          text: `Service de tranche ${form?.id_service ? "modifié" : "cré"}`,
          icon: "success",
        });

        reset();
      }
    });
  };

  const deleteData = (value) => {
    Swal.fire({
      title: "Gestion des services",
      text: "Voulez-vous vraiment supprimer le service ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await deleteServiceTranche(value.id_service).unwrap();

        Swal.fire({
          title: "Félicitations!",
          text: `Service de tranche supprimé`,
          icon: "success",
        });

        reset();
      }
    });
  };

  const exportCSV = (selectionOnly) => {
    // dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        const title = "Liste des services de tranches de numérotation"; // Titre de votre document
        const tableStartY = 20; // Position de départ du tableau
        let isFirstPage = true; // Indicateur pour la première page

        // Configuration du tableau
        const tableConfig = {
          startY: tableStartY, // Position de départ du tableau
          theme: "grid", // Thème du tableau
          styles: {
            overflow: "linebreak", // Gérer le renvoi à la ligne automatique
          },
          columnStyles: {
            // Styles des colonnes
            // Ajoutez les styles pour chaque colonne si nécessaire
          },
          didDrawPage: (data) => {
            // Afficher le titre uniquement sur la première page
            if (isFirstPage) {
              doc.text(title, 14, 15);
              isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
            }

            // Gérer la position de départ du tableau pour le centrage
            const tableWidth = data.table.width;
            const pageWidth = doc.internal.pageSize.width;
            const x = (pageWidth - tableWidth) / 2;
            const y = tableStartY - 10;
            data.cursor.y = y;
            data.cursor.x = x;
          },
        };

        // Entêtes du tableau
        const header = exportColumns.map((col) => col.title);

        // Données du tableau
        const data = serviceTab.map((row, rowIndex) =>
          exportColumns.map((col) => {
            if (col.dataKey == "num") {
              return rowIndex + 1; // Auto-incrémenter le numéro de ligne
            } else {
              return row[col.dataKey]; // Accéder aux données avec le champ de la colonne
            }
          })
        );

        // Génération du tableau dans le PDF
        doc.autoTable(header, data, tableConfig);

        // Enregistrement du PDF
        doc.save(fileName + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(serviceTab);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const rowClassName = (rowData, index) => {
    return index !== serviceTab.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        <OverlayTrigger placement={"top"} overlay={<Tol>Modifier</Tol>}>
          <Btn
            onClick={() => setForm(rowData)}
            className="badge bg-success p-2 mr-1"
            style={{ borderRadius: 30 }}
          >
            <i className="ri-pencil-line  ri-2x"></i>
            <span></span>
          </Btn>
        </OverlayTrigger>
        <OverlayTrigger placement={"top"} overlay={<Tol>Modifier</Tol>}>
          <Btn
            onClick={() => deleteData(rowData)}
            className="badge bg-danger p-2"
            style={{ borderRadius: 30 }}
          >
            <i className="ri-delete-bin-6-line  ri-2x"></i>
            <span></span>
          </Btn>
        </OverlayTrigger>
      </div>
    );
  };

  const Head = () => (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche ..."
          />
        </div>
      </div>
      <div className="container_export">
        <OverlayTrigger placement={"top"} overlay={<Tol>Exportation PDF.</Tol>}>
          <Button
            type="button"
            icon="pi pi-file-pdf"
            rounded
            onClick={exportPdf}
            className=""
            data-pr-tooltip="PDF"
            style={{ borderRadius: 30 }}
            severity="danger"
            size="small"
          />
        </OverlayTrigger>

        <OverlayTrigger
          placement={"top"}
          overlay={<Tol>Exportation Excel.</Tol>}
        >
          <Button
            type="button"
            icon="pi pi-file-excel"
            className=""
            severity="success"
            rounded
            onClick={exportExcel}
            data-pr-tooltip="Excel"
            style={{ borderRadius: 30 }}
            size="small"
          />
        </OverlayTrigger>

        <OverlayTrigger placement={"top"} overlay={<Tol>Exportation Csv.</Tol>}>
          <Button
            type="button"
            icon="pi pi-file"
            rounded
            onClick={() => exportCSV(false)}
            className=""
            data-pr-tooltip="CSV"
            style={{ borderRadius: 30 }}
            size="small"
            severity="secondary"
          />
        </OverlayTrigger>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">
              {" "}
              Gestion des services de tranches de numérotation{" "}
            </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">Liste des services</span>
            <hr style={{ width: "100%" }} />

            <div className="card" style={{ width: "100%" }}>
              <Tooltip target=".export-buttons>button" position="bottom" />
              <style>{`.table-row td {border-bottom: 1px solid #dee2e6;}`}</style>

              <DataTable
                value={serviceTab}
                header={<Head />}
                tableStyle={{ minWidth: "100%", fontSize: 12 }}
                globalFilter={search}
                globalFilterFields={["lib_service"]}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName}
                loading={isLoading}
              >
                {cols.map((col, index) => (
                  <Column
                    key={index}
                    field={col.field}
                    header={col.header}
                    sortable={col.sortable}
                    body={
                      col.field === "actions"
                        ? actionBodyTemplate
                        : col.field === "num"
                        ? (data, options) => options.rowIndex + 1
                        : null
                    }
                  />
                ))}
              </DataTable>
            </div>
          </div>

          <div className="second_container">
            <span className="titre_cat">Nouveau service</span>
            <hr style={{ width: "100%" }} />
            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form mb-3">
                  <div className="container_label">
                    <Form.Label htmlFor="lib">Service</Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="lib"
                      value={form.lib_service}
                      onChange={(e) =>
                        setForm({ ...form, lib_service: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="container_btn">
                  <Btn variant="primary" onClick={() => reset()}>
                    Annuler
                  </Btn>
                  <Btn
                    variant="danger"
                    disabled={disabled}
                    onClick={() => save()}
                  >
                    Enregistrer
                  </Btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default ServiceTranche;
