import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export default function TableListePlaintes(props) {
    const [products, setProducts] = useState([
        {
            code: 1,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 1
        },
        {
            code: 2,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 2
        },
        {
            code: 3,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 3
        },
        {
            code: 4,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 4
        },
        {
            code: 5,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 5
        },
        {
            code: 6,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 6
        },
        {
            code: 7,
            cat: 'Plainte commercial',
            op: 'MTN',
            pl: 'Agent',
            ref: 'A0025',
            cd : 7
        },
]);
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);

    const cols = [
        { field: 'code', header: 'Code', sortable: true },
        { field: 'cat', header: 'Catégorie', sortable: true },
        { field: 'op', header: 'Opérateur', sortable: true },
        { field: 'pl', header: 'identité du plaignant', sortable: true },
        { field: 'ref', header: 'Réf. opérateur', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false } // Nouvelle colonne pour les actions
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    useEffect(() => {
        const updatedProducts = products.map((product) => ({
            ...product,
            actions: true // Mettez ici la logique pour les actions
        }));
        setProducts(updatedProducts);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('plainte.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'plainte');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="secondary"/>
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} />
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}}/>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                

<a  onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{borderRadius : 30}}><i class="ri-eye-line  ri-2x"></i><span></span></a> 

<a  onClick={() => handleDelete(rowData)} class="badge bg-danger p-2 " style={{borderRadius : 30}}><i class="ri-archive-line  ri-2x"></i><span></span></a> 
            </div>
        );
    };

    const handleEdit = (rowData) => {
        props.handleShow()
    };

    const handleDelete = (rowData) => {
        props.handleShow1()
    };

    const rowClassName = (rowData, index) => {
        return index !== products.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };

    return (
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            
            <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

<DataTable
            ref={dt}
            value={products}
            header={header}
            tableStyle={{ minWidth: '100%' , fontSize : 12}}
            globalFilter={globalFilter}
            emptyMessage="Pas de résultat."
            paginator
            rows={7}
            rowClassName={rowClassName} // Applique la classe spécifiée aux lignes
        >
            {cols.map((col, index) => {
                if (col.field === 'st') {
                    return (
                        <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                          
                        />
                    );
                } else if (col.field === 'actions') {
                    return (
                        <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                            body={actionBodyTemplate}
                        />
                    );
                } else {
                    return (
                        <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            sortable={col.sortable}
                        />
                    );
                }
            })}
        </DataTable>
        </div>
    );
}
