import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import userReducer from './slice/userSlice'
// import globalReducer from './slice/globalSlice'
import plainteReducer from './slice/plainteSlice'
import operateurReducer from './slice/operateurSlice'
import infoUserSlice from './slice/infoUserSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        user: userReducer,
        // global: globalReducer,
        plainte: plainteReducer,
        operateur: operateurReducer,
        user_id: infoUserSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.REACT_APP_ENV === 'dev' 
})