import { apiSlice } from "../api/apiSlice"

export const periodiciteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPeriodicities: builder.query({
            query: () => ({
                url: '/obligation/periodicite',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Periodicities', id: 'LIST' },
                    ...result.map(item => ({ type: 'Periodicities', id: item.id_periode }))
                ] : ['Periodicities']
            }
        }),

        createPeriodicite: builder.mutation({
            query: body => ({
                url: '/obligation/periodicite/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Periodicities']
        }),

        updatePeriodicite: builder.mutation({
            query: body => ({
                url: `/obligation/periodicite/update/${body.id_periode}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Periodicities']
        }),
        deletePeriodicite: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/periodicite/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Periodicities'],
          }),

    })
})

export const {
    useGetPeriodicitiesQuery,
    useCreatePeriodiciteMutation,
    useUpdatePeriodiciteMutation,
    useDeletePeriodiciteMutation,
} = periodiciteApiSlice