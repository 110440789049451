import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";
import { useGetPlaintesQuery } from "../redux/apiSlice/plainteApiSlice.js";
import DashCards from "./DashCards.js";
import { Skeleton } from "@mui/material";
import "./styleDash.css";
import moment from "moment";
import {
  useGetAllPlainteQuery,
  useGetPlainteAvecStatutQuery,
} from "../redux/apiSlice/statistiquePlainteApiSlice.js";
import { useCreateLogPageMutation } from "../redux/apiSlice/logPageApiSlice.js";
import TableClassification from "../pages/statistique/obligation/dataTables/TableClassification.js";
import ShieldIcon from "@rsuite/icons/Shield";
import PageIcon from "@rsuite/icons/Page";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import DeviceIcon from "@rsuite/icons/Device";
import TextImageIcon from "@rsuite/icons/TextImage";

export default function DashboardPlainte() {
  // const listePlainte=localStorage.getItemItem('plaintes', JSON.stringify({ liste: plaintes }))
  // console.log("liste plainte",listePlainte)
  // Plainte
  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
    isSuccess: isSuccessPlaintes,
  } = useGetPlaintesQuery();
  const { data: nombreStatut1 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 1,
  });
  const { data: nombreStatut2 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 2,
  });
  const { data: nombreStatut3 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 3,
  });
  const { data: nombreStatut4 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 4,
  });
  const { data: nombreStatut5 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 5,
  });
  const { data: nombreStatut6 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 6,
  });
  const { data: nombreStatut7 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 7,
  });
  const { data: nombreStatut8 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 8,
  });
  const { data: nombreStatut9 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 9,
  });
  const { data: nombreStatut10 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 10,
  });
  const { data: nombreStatut11 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 11,
  });

  const { data: listePlainte, isLoading: loadListePlainte } =
    useGetAllPlainteQuery();
  // graphe

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user || !user.id) {
      window.location.href = "/";
    }
  }, [user]); 
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Dashboard plainte" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  useEffect(() => {
    if (isSuccessPlaintes) {
      const selectedItems = plaintes;
      // Additional logic or state updates
      //setAccuse({ liste: selectedItems })
      localStorage.setItem(
        "plaintes",
        JSON.stringify({ liste: selectedItems })
      );
    }
  }, [isSuccessPlaintes, plaintes]);
  const nbrePlaintePreQualifiee = () => {
    if (listePlainte && listePlainte.length > 0)
      return listePlainte.filter(
        (plt) =>
          plt.id_type_req === null &&
          plt?.Operateur?.id_operateur === user?.id_operateur
      );
    return [];
  };
  const plainteReceptionneeParOp = nombreStatut2?.filter(
    (plt) => plt?.id_operateur === user?.id_operateur
  );
  const plainteEnCompletudeParOp = nombreStatut4?.filter(
    (plt) => plt?.id_operateur === user?.id_operateur
  );
  const plainteEnInstructionParOp = nombreStatut5?.filter(
    (plt) => plt?.id_operateur === user?.id_operateur
  );
  const plainteEnAvisRegParOp = nombreStatut10?.filter(
    (plt) => plt?.id_operateur === user?.id_operateur
  );

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-2">
        <div className="d-md-flex flex-column">
          <h4 className="main-title mb-0">Tableau de Bord</h4>
        </div>
        <Row>
          {loadListePlainte && (
            <Col className="d-flex flex-column">
              <p>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <div className="d-flex flex-row" style={{ flexWrap: "wrap" }}>
                {[1, 2, 3, 4, 5].map((item, key) => (
                  <div key={key} style={{ margin: "10px 10px" }}>
                    <Skeleton height={140} width={180} />
                  </div>
                ))}
              </div>
            </Col>
          )}
          {!loadListePlainte && (
            <>
              <Col className="titre_compatiment">Plainte</Col>
              <Col sm="6" xl="12" className="disposition_card_dash">
                <DashCards
                  icon={
                    <ShieldIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Pré-qualification"
                  nbreElt={nbrePlaintePreQualifiee().length}
                  bordureBas="bleu"
                />
                <DashCards
                  icon={
                    <PageIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Plainte réceptionnée"
                  nbreElt={
                    plainteReceptionneeParOp?.length > 0
                      ? plainteReceptionneeParOp?.length
                      : 0
                  }
                  bordureBas="bleu"
                />
                <DashCards
                  icon={
                    <DeviceIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Complétude d'info"
                  nbreElt={
                    plainteEnCompletudeParOp?.length > 0
                      ? plainteEnCompletudeParOp?.length
                      : 0
                  }
                  bordureBas="jaune"
                />
                <DashCards
                  icon={
                    <TextImageIcon
                      style={{ fontSize: "2.5rem", color: "blue" }}
                    />
                  }
                  titreCard="Instruction"
                  nbreElt={
                    plainteEnInstructionParOp?.length > 0
                      ? plainteEnInstructionParOp?.length
                      : 0
                  }
                  bordureBas="rouge"
                />
                <DashCards
                  icon={
                    <FolderFillIcon
                      style={{ fontSize: "2.5rem", color: "blue" }}
                    />
                  }
                  titreCard="Avis de règlement"
                  nbreElt={
                    plainteEnAvisRegParOp?.length > 0
                      ? plainteEnAvisRegParOp?.length
                      : 0
                  }
                  bordureBas="bleu"
                />
              </Col>
            </>
          )}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
