import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import TableCatPrestation from "./dataTable/TableCatPrestation";
import { Loader } from "rsuite";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  useGetCatPrestationQuery,
  useCreateCatPrestationMutation,
  useUpdateCatPrestationMutation,
  useDeleteCatPrestationMutation,
} from "../../redux/apiSlice/categoriePrestationApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import Swal from "sweetalert2";
import { getUserInfo } from "../../redux/slice/userSlice";
import { useSelector } from "react-redux";
export default function Plateforme() {
  const {
    data: catPrestation,
    refetch: refetchCatPrestation,
    isLoading: loadCatPrestation,
  } = useGetCatPrestationQuery();
  const [createCatPrestation, { isLoading: loadCreateCatPrestation }] =
    useCreateCatPrestationMutation();
  const [updateCatPrestation, { isLoading: loadUpdateCatPrestation }] =
    useUpdateCatPrestationMutation();
  const [deleteCatPrestation] = useDeleteCatPrestationMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  // modal
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de creation de catégorie de prestation",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog();
  }, []);

  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  const initCatPrestation = {
    id_cat_pret: null,
    lib_cat_prest: "",
  };

  const [creerCatPrestation, setCreerCatPrestation] = useState({
    ...initCatPrestation,
  });
  const [idCatPrestation, setIdCatPrestation] = useState(0);

  const isRequired = creerCatPrestation.lib_cat_prest === "";

  const [errorMsg1, setErrorMsg1] = useState({
    lib_cat_prest: "",
  });

  const informationMessage = {
    lib_cat_prest: "Veuillez saisir le libellé",
  };

  const afficherMessage = (objetMsg) => {
    Object.entries(objetMsg).forEach(([key, message]) => {
      if (creerCatPrestation[key] === "") {
        toast.warning(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const recupererChamp = (field, value) => {
    setCreerCatPrestation({ ...creerCatPrestation, [field]: value });

    if (field === "lib_cat_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        lib_cat_prest: "Veuillez saisir le libellé",
      }));
    } else {
      setErrorMsg1((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const viderChamps = () => {
    setCreerCatPrestation({ ...initCatPrestation });
  };
  const saveCatPrestation = async () => {
    try {
      if (creerCatPrestation.id_cat_prest)
        await updateCatPrestation({
          ...creerCatPrestation,
          id_cat_prest: creerCatPrestation.id_cat_prest,
          lib_cat_prest: creerCatPrestation.lib_cat_prest,
        }).unwrap();
      else
        await createCatPrestation({
          lib_cat_prest: creerCatPrestation.lib_cat_prest,
        }).unwrap();
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success",
      });

      refetchCatPrestation();
      setCreerCatPrestation({ ...initCatPrestation });
    } catch (error) {
      // console.log("error", error);
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "Erreur lors de la creation!",
      });
    }
  };

  const suppressionPlateforme = async () => {
    try {
      let retour_supp = await deleteCatPrestation({
        id_cat_prest: idCatPrestation,
      }).unwrap();
      if (retour_supp) {
        refetchCatPrestation();
        handleCloseDelete();
        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success",
        });
      }
    } catch (error) {
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "Erreur lors de la suppression",
      });
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Gestion des categories de prestation
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Liste des categories de prestation à de Arcep
            </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">
              Liste des categories de prestation
            </span>

            <hr style={{ width: "100%" }} />

            {loadCatPrestation ? (
              <p className="w-100 d-flex flex-row justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </p>
            ) : (
              <TableCatPrestation
                creerCatPrestation={creerCatPrestation}
                setCreerCatPrestation={setCreerCatPrestation}
                catPrestation={catPrestation}
                handleShowDelete={handleShowDelete}
                setIdCatPrestation={setIdCatPrestation}
              />
            )}
          </div>

          <div className="second_container">
            <span className="titre_cat">Nouvelle categorie de prestation</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Libellé * :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="libellé"
                      value={creerCatPrestation.lib_cat_prest}
                      onChange={(e) =>
                        recupererChamp("lib_cat_prest", e.target.value)
                      }
                    />
                    {errorMsg1.lib_cat_prest && (
                      <span className="text-danger">
                        {errorMsg1.lib_cat_prest}
                      </span>
                    )}
                  </div>
                </div>

                <div className="container_btn">
                  <Button variant="danger" onClick={() => viderChamps()}>
                    Annuler
                  </Button>

                  <Button
                    variant="success"
                    disabled={
                      loadCreateCatPrestation || loadUpdateCatPrestation
                    }
                    onClick={() =>
                      isRequired
                        ? afficherMessage(informationMessage)
                        : saveCatPrestation()
                    }
                  >
                    {loadCreateCatPrestation || loadUpdateCatPrestation ? (
                      <Loader size="md" content="En cours..." />
                    ) : (
                      <span>Enregister</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------modal de modification---------- */}
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Etês-vous sûr de vouloir supprimer ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Annuler
            </Button>
            <Button variant="danger" onClick={suppressionPlateforme}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
