import React, { Fragment, useEffect, useState } from 'react'

import { Button as Btn, Form } from "react-bootstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import Swal from "sweetalert2";
import '../numerotation/styleNumerotation.css'
import {  Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
import Footer from '../../layouts/Footer'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";

import { useGetTokenApiQuery } from '../../redux/apiSlice/tokenApiSlice';
const Api = () => {

    const {data : token, isLoading} = useGetTokenApiQuery()
    const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

    const fileName = 'api_token'
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : ''
    const cols = [

        { field: 'token', header: 'Token', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false }
    ];
    const colsExport = [
        { field: 'token', header: 'Token', sortable: true },
    ];
    const exportColumns = colsExport.map((col) => ({ title: col.header, dataKey: col.field }));
    const initForm = {lib_tranche: '', desc_tranche: ''}

    const [skin, setSkin] = useState(currentSkin)
    const [search, setSearch] = useState('')
    const [form, setForm] = useState({...initForm})

    const [tokenState, setTokenState] = useState(null)

    const switchSkin = (skin) => {
        if (skin === 'dark') {
          const btnWhite = document.getElementsByClassName('btn-white');
    
          for (const btn of btnWhite) {
            btn.classList.add('btn-outline-primary');
            btn.classList.remove('btn-white');
          }
        } else {
          const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
    
          for (const btn of btnOutlinePrimary) {
            btn.classList.remove('btn-outline-primary');
            btn.classList.add('btn-white');
          }
        }
    }

    switchSkin(skin);
    const createLog = async ()=>{
        try {
          await createLogPage({content: "Page de api token"}).unwrap();  
      } catch (error) {
        console.log('error', error)
      }
      } 
      const user = useSelector(getUserInfo);

      useEffect(() => {
        createLog()   
      }, []);

      useEffect(() => {
        if (token) {
            setTokenState([{id : 1 , token}])
        }    
      }, [token]);
      
    useEffect(() => {  
    if (!user?.id) {
        window.location.href = "/";
      }
    switchSkin(skin);
    }, [skin]);

    
    const exportCSV = (selectionOnly) => {
        // dt.current.exportCSV({ selectionOnly });
    };
 
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default();
                const title = "Api Token"; // Titre de votre document
                const tableStartY = 20; // Position de départ du tableau
                let isFirstPage = true; // Indicateur pour la première page
                // Configuration du tableau
                const tableConfig = {
                    startY: tableStartY, // Position de départ du tableau
                    theme: 'grid', // Thème du tableau
                    styles: {
                        overflow: 'linebreak', // Gérer le renvoi à la ligne automatique
                    },
                    columnStyles: { // Styles des colonnes
                        // Ajoutez les styles pour chaque colonne si nécessaire
                    },
                    didDrawPage: (data) => {
                        // Afficher le titre uniquement sur la première page
                        if (isFirstPage) {
                            doc.text(title, 14, 15);
                            isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
                        }
    
                        // Gérer la position de départ du tableau pour le centrage
                        const tableWidth = data.table.width;
                        const pageWidth = doc.internal.pageSize.width;
                        const x = (pageWidth - tableWidth) / 2;
                        const y = tableStartY - 10;
                        data.cursor.y = y;
                        data.cursor.x = x;
                    },
                };
    
                // Entêtes du tableau
                const header = exportColumns.map((col) => col.title);
    
                  // Données du tableau
                  const data = tokenState.map((row, rowIndex) => exportColumns.map((col) => {
                      return row[col.dataKey]; // Accéder aux données avec le champ de la colonne
  
                }));
    
    
                // Génération du tableau dans le PDF
                doc.autoTable(header, data, tableConfig);
    
                // Enregistrement du PDF
                doc.save(fileName+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(tokenState);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const rowClassName = (rowData, index) => {
        return index !== tokenState.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };
    
    const copyToClipboard = (text) => {
        // Copie le texte dans le presse-papiers
        navigator.clipboard.writeText(text)
          .then(() => {
             Swal.fire({
        title: "SUCCES!",
        text: "Token copié",
        icon: "success",
      });
          })
          .catch(err => {
           
            Swal.fire({
                title: "Attention!",
                text: "Une erreur s'est produite",
                icon: "error",
              });
          });
      };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Cliquer pour copier
                    </Tol>
                  }
                >
                <Btn onClick={() => copyToClipboard(rowData.token)} className="badge bg-success p-2 mr-1" style={{ borderRadius: 30 }}><i class="ri-file-copy-line" style={{ fontSize: '25px' }}></i><span></span></Btn>
                </OverlayTrigger>
            </div>
        );
    };

    const Head = () => (
        <div className="container_header_data_table">
            <div className="flex align-items-center">

                <div className="p-input-icon-left ml-3">
                    
                </div>
            </div>
            <div className='container_export'>
            <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation PDF.
                    </Tol>
                  }
                >
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="danger" size="small"/>
                </OverlayTrigger>

                <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Excel.
                    </Tol>
                  }
                >
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                </OverlayTrigger>

                <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Csv.
                    </Tol>
                  }
                >
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small"   severity="secondary"/>
                </OverlayTrigger>
            </div>
        </div>
    );

    return (
        <Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0"> API TOKEN </h4>
                    </div>
                </div>

                <div className="container_liste_form">
                        <span className="titre_cat">Token</span>
                        <hr style={{ width: '100%' }} />

                        <div className="card" style={{ width: '100%',  }}>
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <style>{`.table-row td {border-bottom: 1px solid #dee2e6;}`}</style>

                            <DataTable
                                value={tokenState}
                                header={<Head />}
                                tableStyle={{ minWidth: '100%', fontSize: 12 }}
                                paginator
                                rows={10}
                                rowClassName={rowClassName}
                                loading={isLoading}
                            >
                                {cols.map((col, index) => (
                                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable} body={col.field === 'actions' ? actionBodyTemplate :  null} />
                                ))}
                            </DataTable>

                        </div>
                 


                </div>

                <Footer />
            </div>
        </Fragment>
    )
}

export default Api
