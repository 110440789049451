import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import TableUser from "./dataTable/TableUser";
import Spinner from "react-bootstrap/Spinner";
import {
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../redux/apiSlice/userApiSlice";

import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import "./styleUser.css";

import Swal from "sweetalert2";
import { useGetProfilQuery } from "../../redux/apiSlice/profileApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";
export default function Plateforme() {
  const {
    data: user,
    refetch: refetchUser,
    isLoading: loadUser,
  } = useGetUserQuery();
  const [createUser, { isLoading: loadCreateUser }] = useCreateUserMutation();
  const [updateUser, { isLoading: loadUpdateUser }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: loadDeleteUser }] = useDeleteUserMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  // modal
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const { data: operateur = [], isLoading: loadOperateur } =
    useGetOperateursQuery();
  const { data: lesProfile } = useGetProfilQuery();

  switchSkin(skin);

  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de creation des utilisateurs"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  }
  const connectVerify = useSelector(getUserInfo);
  useEffect(() => {
    if (!connectVerify?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
    createLog()

  }, [skin]);

  const initUser = {
    id_user: null,
    nom_user: "",
    email_user: "",
    organisme_user: "Organisme evolutic",
    pwd_user: "",
    id_profil: 0,
    id_operateur: 0,
    statut_user: 0,
    parent_user: 0,
  };

  const [creerUser, setCreerUser] = useState({ ...initUser });
  const [idUser, setIdUser] = useState(0);

  const checkEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isRequired =
    creerUser.nom_user === "" ||
    creerUser.email_user === "" ||
    checkEmail(creerUser.email_user) === false ||
    creerUser.pwd_user === "" ||
    creerUser.id_operateur === "" ||
    creerUser.id_profil === "";

  const recupererChamp = (etiquette, value) => {
    setCreerUser({ ...creerUser, [etiquette]: value });
  };

  // const handleFileChange = async (e) => {
  //   const filesImg = e.target.files[0];
  //   recupererChamp("image", filesImg);
  // };
  const viderChamps = () => {
    setCreerUser({ ...initUser });
  };
  const saveUser = async () => {
    try {
      if (creerUser.id_user)
        await updateUser({
          ...creerUser,
          id_user: creerUser.id_user,
        }).unwrap();
      else {
        await createUser(creerUser).unwrap();
      }

      Swal.fire({
        title: "Terminé",
        text: "Prestation saugardée!",
        icon: "success",
      });

      refetchUser();
      setCreerUser({ ...initUser });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est subvenue lors de la création",
        footer: '<a href="#">Essayer une autre fois?</a>',
      });
    }
  };

  const suppressionUser = async () => {
    try {
      let retour_supp = await deleteUser({
        id_user: idUser,
      }).unwrap();
      if (retour_supp) {
        refetchUser();
        handleCloseDelete();
        Swal.fire({
          title: "Terminé!",
          text: "Suppression réussie!",
          icon: "success",
        });
      }
    } catch (error) {
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erreur lors de la suppression",
        footer: '<a href="#">Essayer une autre fois?</a>',
      });
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Gestion des utilisateurs
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Liste des utilisateurs de l'Arcep
            </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">Liste des utilisateurs</span>

            <hr style={{ width: "100%" }} />

            {!loadUser && (
              <TableUser
                creerUser={creerUser}
                setCreerUser={setCreerUser}
                user={user}
                handleShowDelete={handleShowDelete}
                setIdUser={setIdUser}
              />
            )}
          </div>
          <div className="second_container">
            <span className="titre_cat">Nouveau utilisateur</span>

            <hr style={{ width: "100%" }} />

            <div className="container_border">
              {isRequired && (
                <span className="w-100 text-danger text-center mb-4">
                  Un ou plusieurs champs sont vide
                </span>
              )}

              <div className="sous_container_form mb-4 ">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Nom utilisateur *:
                </Form.Label>
                <div style={{ width: "90%" }}>
                  <Form.Control
                    type="text"
                    id="exampleFormControlInput1"
                    placeholder="Jules BOSSOU"
                    value={creerUser.nom_user}
                    onChange={(e) => recupererChamp("nom_user", e.target.value)}
                  />
                </div>
              </div>

              <div className="sous_container_form mb-4">
                <Form.Label htmlFor="exampleFormControlInput2">
                  Email utilisateur *:
                </Form.Label>
                <div style={{ width: "90%" }}>
                  <Form.Control
                    id="exampleFormControlInput2"
                    type="email"
                    placeholder="jules@gmail.com"
                    value={creerUser.email_user}
                    onChange={(e) =>
                      recupererChamp("email_user", e.target.value)
                    }
                  />
                </div>
                {creerUser.email_user !== "" &&
                  !checkEmail(creerUser.email_user) && (
                    <span className="text-danger">Email invalide</span>
                  )}
              </div>
              <div className="sous_container_form mb-4 ">
                <Form.Label htmlFor="exampleFormControlInput2">
                  Mot de passe *:
                </Form.Label>
                <div style={{ width: "75%" }}>
                  <Form.Control
                    type="password"
                    class="form-control"
                    id="exampleFormControlInput2"
                    placeholder="Mot de passe"
                    value={creerUser.pwd_user}
                    onChange={(e) => recupererChamp("pwd_user", e.target.value)}
                  />
                </div>
              </div>
              <div className="sous_container_form mb-4 ">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Opérateur *:
                </Form.Label>
                <div style={{ width: "75%" }}>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) =>
                      recupererChamp("id_operateur", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>

                    {operateur.length > 0 &&
                      operateur.map((item) => (
                        <option value={item.id_operateur}>
                          {item.nom_operateur}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
              <div className="sous_container_form mb-4 ">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Profile *:
                </Form.Label>
                <div style={{ width: "75%" }}>
                  <Form.Select
                    value={creerUser.id_profil}
                    onChange={(e) =>
                      recupererChamp("id_profil", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>
                    {lesProfile?.map((prof, key) => (
                      <option value={prof.id_profil} key={key}>
                        {prof.nom_profil}
                      </option>
                    ))}

                    {/* <option value="2">Profile2</option>
                  <option value="3">Profile3</option> */}
                    {/* {categoriePrestation?.map((cat, key) => (
                    <option value={cat.id_cat_prest}>
                      {cat.lib_cat_prest}
                    </option>
                  ))} */}
                  </Form.Select>
                </div>
              </div>

              <div className="container_btn d-flex flex-row justify-content-center">
                <Button variant="danger" onClick={() => viderChamps()}>
                  Annuler
                </Button>

                <Button
                  variant="success"
                  onClick={() => saveUser()}
                  disabled={isRequired || loadCreateUser || loadUpdateUser}
                >
                  {loadCreateUser || loadUpdateUser ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Enregister</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------modal de modification---------- */}
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Etês-vous sûr de vouloir supprimer ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Annuler
            </Button>
            <Button
              variant="danger"
              disabled={loadDeleteUser}
              onClick={suppressionUser}
            >
              {loadDeleteUser ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Valider</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
