import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import {  Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
import { getUserInfo } from "../../../redux/slice/userSlice";
import { useSelector} from "react-redux";
import Swal from "sweetalert2";
import { useArchivePlainteMutation } from "../../../redux/apiSlice/plainteApiSlice";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button as Btn, Form, Modal, Row, Col } from "react-bootstrap";


export default function TableListePlaintes(props) {

  const [plaintesFront, setPlaintFront] = useState([]);
  const { plaintes, setPlainteData } = props;
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [id_archive, setId_archive] = useState("");
  const handleCloseCloture = () => {setShow(false);};
  const handleShowCloture = (id_plt) => {
    setId_archive(id_plt)
    setShow(true)
  };
  const editorRef = useRef();

  const handleChange = (event, editor) => {
    const content = editor.getData()
    setEditorContent(content);
  };

  const [archivePlainte, { isLoading: loadArchive }] =
  useArchivePlainteMutation();

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const formatDate = (timestamp) => {

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
        return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();


    return formattedDate;
};


const userInfo = useSelector(getUserInfo);


  useEffect(() => {

    const tab = plaintes?.liste.filter((item)=>(item.id_type_plt != null && item.id_service != null ))
  
    const operateur_plainte = tab.filter((item)=>parseInt(item.id_operateur) == parseInt(userInfo.id_operateur))

    const rsul = userInfo.profil == "Administrateur" || userInfo.profil =="Super Administrateur" ? tab : operateur_plainte

    const updatedPlaintesFront = rsul.map((plainte) => {
      
      const timestamp = typeof plainte.date_plt === 'string' ? parseInt(plainte.date_plt, 10) : plainte.date_plt;
  
      // Utilisez le nom correct pour le timestamp
      const dat = formatDate(timestamp);
  
      return {
          id_plt: plainte?.id_plt,
          code: plainte?.num_suivi_plt,
          cat: plainte?.Type_plainte?.lib_type_plt,
          op: plainte?.Operateur?.nom_operateur,
          dt: dat,
          // dt: plainte.date_constat,
          pl: plainte?.nom_plt+" "+plainte?.prenom_plt,
          id_stat_plt: plainte?.id_stat_plt,
          ref: plainte?.ref_plt,
          st: plainte?.Statut_plainte?.libelle,
          cd: plainte?.Statut_plainte?.id_stat_plt,
          actions: true,
      };
  });
  
    setProducts(updatedPlaintesFront);
   
  }, [plaintes?.liste]);
  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);

  const cols = [
    { field: "code", header: "Code de suivi", sortable: true },
    { field: "cat", header: "Catégorie", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date de soumission", sortable: true },
    { field: "pl", header: "Identité du plaignant", sortable: true },
    { field: "ref", header: "Réf. opérateur", sortable: true },
    { field: "st", header: "statut", sortable: true },
    { field: "actions", header: "Actions", sortable: false }, // Nouvelle colonne pour les actions
  ];

  const colsExport = [
    { field: "num", header: " N° ", sortable: true },
    { field: "code", header: "Code de suivi", sortable: true },
    { field: "code", header: "Code de suivi", sortable: true },
    { field: "cat", header: "Catégorie", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date de soumission", sortable: true },
    { field: "pl", header: "Identité du plaignant", sortable: true },
    { field: "ref", header: "Réf. opérateur", sortable: true },
    { field: "st", header: "statut", sortable: true },
  ];

  const exportColumns = colsExport.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));


  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  
  const exportPdf = () => {
    
    import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
            const doc = new jsPDF.default();

            const title = "Liste des plaintes"; // Titre de votre document
            const tableStartY = 20; // Position de départ du tableau
            let isFirstPage = true; // Indicateur pour la première page

            // Configuration du tableau
            const tableConfig = {
                startY: tableStartY, // Position de départ du tableau
                theme: 'grid', // Thème du tableau
                styles: {
                    overflow: 'linebreak', // Gérer le renvoi à la ligne automatique
                },
                columnStyles: { // Styles des colonnes
                    // Ajoutez les styles pour chaque colonne si nécessaire
                },
                didDrawPage: (data) => {
                    // Afficher le titre uniquement sur la première page
                    if (isFirstPage) {
                        doc.text(title, 14, 15);
                        isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
                    }

                    // Gérer la position de départ du tableau pour le centrage
                    const tableWidth = data.table.width;
                    const pageWidth = doc.internal.pageSize.width;
                    const x = (pageWidth - tableWidth) / 2;
                    const y = tableStartY - 10;
                    data.cursor.y = y;
                    data.cursor.x = x;
                },
            };

            // Entêtes du tableau
            const header = exportColumns.map((col) => col.title);

              // Données du tableau
              const data =products.map((row, rowIndex) => exportColumns.map((col) => {
               
                if (col.dataKey == 'num') {
                  
                    return rowIndex + 1; // Auto-incrémenter le numéro de ligne
                }else{
                  return row[col.dataKey]; // Accéder aux données avec le champ de la colonne

                }
            }));


            // Génération du tableau dans le PDF
            doc.autoTable(header, data, tableConfig);

            // Enregistrement du PDF
            doc.save("plaintes.pdf");
        });
    });
};



  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
        // Créer les entêtes à partir de colsExport
        const headers = colsExport.map(col => col.header);
        const fields = colsExport.map(col => col.field);

        // Créer les données formatées
        const data = products.map((item, index) => {
            let rowData = {};
            fields.forEach((field, i) => {
                if (field === 'num') {
                    rowData[headers[i]] = index + 1; // Numéro auto-incrémenté
                } else {
                    rowData[headers[i]] = item[field];
                }
            });
            return rowData;
        });

        // Ajouter les entêtes comme première ligne des données
        const worksheetData = [headers, ...data.map(Object.values)];

        // Créer la feuille de calcul à partir des données formatées
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        // Générer le buffer Excel
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Sauvegarder le fichier Excel
        saveAsExcelFile(excelBuffer, 'plainte');
    });
};
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case 1:
        return "bg-danger";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-primary";
      case 4:
        return "bg-secondary";
      case 5:
        return "bg-info";
      case 6:
        return "bg-dark";
      case 7:
        return "bg-success";
      case 8:
        return "bg-secondary";
      case 9:
        return "bg-dark";
      case 10:
        return "bg-info";
      case 11:
        return "bg-primary";
      default:
        return "bg-success";
    }
  };


  const getLabel = (status) => {
    switch (status) {
      case 1:
        return "Plainte Soumise";
      case 2:
        return "Plainte Réceptionnée";
      case 3:
        return "Plainte Classée";
      case 4:
        return "En Complétude D'information";
      case 5:
        return "Plainte Instruite";
      case 6:
        return "Plainte En Investigation";
      case 7:
        return "Plainte En Résultat D'investigation";
      case 8:
        return "Plainte En Rapport D'investigation";
      case 9:
        return "Plainte En Avis de Règlement";
      case 10:
        return "Plainte Traitée";
      case 11:
        return "Plainte Traitée";
      default:
        return "Plainte Classée";
    }
  };
  const seeButtonArchive = (status) => {
    switch (status) {
    
      case 3:
        return 1;
      case 10:
        return 1;
      default:
        return 0;
    }
  };

  const createLogActionPlainte = async () => {
    try {
      await createLogPage({ content: "Archivage de plainte" }).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };

  const archiverPlainte = async (id, avis) => {
   handleCloseCloture()
      Swal.fire({
        title: "Action irréversible !",
        text: "Vous vous archiver cette plainte !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

        try {
  
          const retour_archive = await archivePlainte({id, avis}).unwrap();
          createLogActionPlainte();
          if (retour_archive == null) {
            window.location.href = "/toutes_les_plaintes";
          }  
         
         
        } catch (error) {
    

          console.log("error", error)
        }
           
        }
      });

    
   
  };
  const handleEdit = (rowData) => {
    const clickedCode = rowData.code;
    const selectedComplaint = plaintes.liste.find(
      (complaint) => complaint.num_suivi_plt === clickedCode
    );

    if (selectedComplaint) {
      props.handleshow(selectedComplaint)

    }

  };
  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.cd);
    const label = getLabel(rowData.cd);

    return <span className={`badge ${statusClassName}`}>{label}</span>;
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="container_export">
      <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation PDF.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="danger"
        />
           </OverlayTrigger>
           <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Excel.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          // onClick={exportExcel}
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
        
        />
         </OverlayTrigger>
         <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Csv.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          severity="secondary"
        />
        </OverlayTrigger>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">

<OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Détails plainte.
                    </Tol>
                  }
                >
  
    <a onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{ borderRadius: 30, cursor: "pointer" }}>
      <i class="ri-eye-line  ri-2x"></i><span>
        </span>
        </a>
        </OverlayTrigger>
        
{seeButtonArchive(rowData?.id_stat_plt) ==1 && <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Archiver
                    </Tol>
                  }
                >
        <a onClick={() => handleShowCloture(rowData?.id_plt)} class="badge bg-danger p-2 " style={{ borderRadius: 30 , cursor: "pointer"}}><i class="ri-archive-line  ri-2x"></i><span></span></a>
        </OverlayTrigger>}
    
      </div>
    );
  };

 


  const rowClassName = (rowData, index) => {
    return index !== products.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />

      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={products}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={7}
        rowClassName={rowClassName} // Applique la classe spécifiée aux lignes
      >
        {cols.map((col, index) => {
          if (col.field === "st") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={statusBodyTemplate}
              />
            );
          } else if (col.field === "actions") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={actionBodyTemplate}
              />
            );
          } else {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
              />
            );
          }
        })}
      </DataTable>
      <Modal show={show} onHide={handleCloseCloture}  className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Votre commentaire d'archivage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}
              <Form.Group controlId="formBasicText">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      onReady={(editor) => editorRef.current = editor}
                      onChange={handleChange}
                    />
                  </Form.Group>
              
            </Col>
          
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <Btn variant="danger" onClick={()=>handleCloseCloture()}>
                Fermer
              </Btn>
              <Btn variant="success" disabled={editorContent==""} onClick={()=>archiverPlainte(id_archive, editorContent)}>
                Valider
              </Btn>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
