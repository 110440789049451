import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import {  Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
export default function TableCat(props) {
  const {
    faq,
    setCreerFaq,
    creerFaq,
    handleShowDelete ,
    setIdFaq 
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);
  
  const cols = [
    // { field: "id_rens", header: " N°", sortable: true },
    { field: "quest_rens", header: "Question", sortable: true },
    { field: "reponse_rens", header: "Reponse", sortable: true },
    // { field: "lib_cat_rens", header: "Categorie faq", sortable: true },
    { field: "actions", header: "Actions", sortable: false }, // Nouvelle colonne pour les actions
  ];

  const exportColumns = cols.map((col) => ({
    //FFFF
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, faq); //FFFF
        doc.save("faq.pdf");
      });
    });
  };

  
  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
        // Créer les entêtes à partir de colsExport
        const headers = cols.map(col => col.header);
        const fields = cols.map(col => col.field);

        // Créer les données formatées
        const data = faq.map((item, index) => {
            let rowData = {};
            fields.forEach((field, i) => {
                if (field === 'num') {
                    rowData[headers[i]] = index + 1; // Numéro auto-incrémenté
                } else {
                    rowData[headers[i]] = item[field];
                }
            });
            return rowData;
        });

        // Ajouter les entêtes comme première ligne des données
        const worksheetData = [headers, ...data.map(Object.values)];

        // Créer la feuille de calcul à partir des données formatées
        const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        // Générer le buffer Excel
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Sauvegarder le fichier Excel
        saveAsExcelFile(excelBuffer, 'faq');
    });
};

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="container_export">
      <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation PDF.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="danger"
          size="small"
        />
        </OverlayTrigger>
        <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Excel.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
          size="small"
        />
        </OverlayTrigger>
        <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Csv.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          size="small"
          severity="secondary"
        />
        </OverlayTrigger>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
         <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Modifier.
                    </Tol>
                  }
                >
        <a
          onClick={() => handleEdit(rowData)}
          class="badge bg-success p-2 mr-1"
          style={{ borderRadius: 30 }}
          href="#"
        >
          <i class="ri-pencil-line  ri-2x"></i>
          <span></span>
        </a>
        </OverlayTrigger>
        <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Supprimer.
                    </Tol>
                  }
                >
        <a
          onClick={() => handleDelete(rowData)}
          class="badge bg-danger p-2"
          style={{ borderRadius: 30 }}
          href="#"
        >
          <i class="ri-delete-bin-6-line  ri-2x"></i>
          <span></span>
        </a>
        </OverlayTrigger>
      </div>
    );
  };

  const handleEdit = (rowData) => {
    setCreerFaq({
      ...creerFaq,
      id_rens: rowData.id_rens,
      quest_rens: rowData.quest_rens,
      reponse_rens: rowData.reponse_rens,
      id_cat_rens: rowData.id_cat_rens,
    });
  };

  const handleDelete = (rowData) => {
    handleShowDelete()
    setIdFaq (rowData.id_rens)
  };

  const rowClassName = (rowData, index) => {
    return index !== faq.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={faq}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={10}
        rowClassName={rowClassName}
      >
        {cols.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            body={col.field === "actions" ? actionBodyTemplate : null}
          />
        ))}
      </DataTable>
    </div>
  );
}
