import React, {useEffect} from "react";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";


const AvisSatisfaction = (props) => {
  const { plainte } = props;


  const soustitreStyle = {
    fontSize: '1.4em', // Taille de la police
    fontWeight: 'bolder', // Gras
    // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const tabletitreStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'bolder', // Gras
    // color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };


  const contenuStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'normal', // Gras
    // color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  return plainte == "" ? 
  (
    <div class="card">
      <Button variant="primary" disabled style={{ height: 200 }}>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button>
    </div>
  )
  :
  (
    plainte.id_stat_plt == 10 && plainte.avis != null  && (
      <>
        <h4 style={soustitreStyle}>&nbsp; Avis de satisfaction</h4>
        <div class="card ml-5">
          <div class="card-body">
            <div class="table-responsive ">
              <table class="table mb-0 table-striped table-hover">
                <thead>
                  <tr>
                  
                    <th style={tabletitreStyle} scope="col">Avis de satisfaction</th>
                 
                
                  </tr>
                </thead>

                <tbody>
             
                    <tr >
                 
                      <td class=" col" style={contenuStyle}>{plainte.avis}</td>
                      
                      
                    </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
    
  );
    
};

export default AvisSatisfaction;
