import { apiSlice } from "../api/apiSlice"

export const elementCtrlObligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getElementObligations: builder.query({
            query: () => ({
                url: '/obligation/element_ctrl',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'ElementControle', id: 'LIST' },
                    ...result.map(item => ({ type: 'ElementControle', id: item.id_elt }))
                ] : ['ElementControle']
            }
        }),

        createElementObligations: builder.mutation({
            query: body => ({
                url: '/obligation/element_ctrl/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['ElementControle']
        }),

        updateElementObligations: builder.mutation({
            query: body => ({
                url: `/obligation/element_ctrl/update/${body.id_elt}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['ElementControle']
        }),

       
        deleteElementObligations: builder.mutation({
           
            query: (id) => ({
              url: `/obligation/element_ctrl/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['ElementControle'],
          }),
       
    })
})

export const {
    useGetElementObligationsQuery,
    useCreateElementObligationsMutation,
    useUpdateElementObligationsMutation,
    useDeleteElementObligationsMutation,

} = elementCtrlObligationApiSlice
