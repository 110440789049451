import { apiSlice } from "../api/apiSlice";

export const numerotationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //attribution tranche service
    getAttribuerServiceTranche: builder.query({
      query: () => ({
        url: "/numerotation/attribuer_tranche",
        method: "GET",
      }),
      providesTags: ["AttribuerServiceTranches"],
    }),

    createAttribuerServiceTranche: builder.mutation({
      query: (body) => ({
        url: "/numerotation/attribuer_tranche/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AttribuerServiceTranches"],
    }),

    updateAttribuerServiceTranche: builder.mutation({
      query: (body) => ({
        url: `/numerotation/attribuer_tranche/update/${body.id_attr}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["AttribuerServiceTranches"],
    }),

    deleteAttribuerServiceTranche: builder.mutation({
      query: (id) => ({
        url: `/numerotation/attribuer_tranche/del/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AttribuerServiceTranches"],
    }),

    //service tranche
    getServiceTranche: builder.query({
      query: () => ({
        url: "/numerotation/service",
        method: "GET",
      }),
      providesTags: ["ServiceTranches"],
    }),

    createServiceTranche: builder.mutation({
      query: (body) => ({
        url: "/numerotation/service/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ServiceTranches"],
    }),

    updateServiceTranche: builder.mutation({
      query: (body) => ({
        url: `/numerotation/service/update/${body.id_service}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ServiceTranches"],
    }),

    deleteServiceTranche: builder.mutation({
      query: (id) => ({
        url: `/numerotation/service/del/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceTranches"],
    }),

    //sous tranche
    getSousTranche: builder.query({
      query: () => ({
        url: "/numerotation/sous_tranche",
        method: "GET",
      }),
      providesTags: ["SousTranches"],
    }),

    createSousTranche: builder.mutation({
      query: (body) => ({
        url: "/numerotation/sous_tranche/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SousTranches"],
    }),

    updateSousTranche: builder.mutation({
      query: (body) => ({
        url: `/numerotation/sous_tranche/update/${body.id_s_tran}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["SousTranches"],
    }),

    deleteSousTranche: builder.mutation({
      query: (id) => ({
        url: `/numerotation/sous_tranche/del/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SousTranches"],
    }),

    // tranche
    getTranche: builder.query({
      query: () => ({
        url: "/numerotation/tranche",
        method: "GET",
      }),
      providesTags: ["Tranches"],
    }),

    createTranche: builder.mutation({
      query: (body) => ({
        url: "/numerotation/tranche/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tranches"],
    }),

    updateTranche: builder.mutation({
      query: (body) => ({
        url: `/numerotation/tranche/update/${body.id_tranche}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tranches"],
    }),

    deleteTranche: builder.mutation({
      query: (id) => ({
        url: `/numerotation/tranche/del/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tranches"],
    }),

    // lecture ressources 
   getAllRessources: builder.query({
      query: () => ({
          url: `/numerotation/get_all_ressources`,
          method: "GET",
      }),
    providesTags: ['Ressources'],

  }),
   getRessourceDispoTranche: builder.query({
      query: (id) => ({
          url: `/numerotation/get_available/tranche/${id}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoTranche'],

  }),
   getRessourceDispoServiceTranche: builder.query({
      query: (body) => ({
url: `/numerotation/get_available/service/tranche/${body.id_service}/${body.id_tranche}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoTranche'],

  }),
   getRessourceDispoService: builder.query({
      query: (body) => ({
          url: `/numerotation/get_available/service/${body.id_service}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoTranche'],

  }),
   getRessourceAllService: builder.query({
      query: (body) => ({
          url: `/numerotation/get_all_by/service/${body.id_service}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoService'],

  }),
   getRessourceListeTranche: builder.query({
      query: (id_tranche) => ({
          url: `/numerotation/get_all/tranche/${id_tranche}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoTranche'],

  }),
   getRessourceListeSousTranche: builder.query({
      query: (id_sous_tranche) => ({
          url: `numerotation/get_all/sous_tranche/${id_sous_tranche}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoSousTranche'],

  }),
   getRessourceOccupeTranche: builder.query({
      query: (id_tranche) => ({
          url: `/numerotation/get_busy/tranche/${id_tranche}`,
          method: "GET",
      }),
    providesTags: ['RessourcesDispoTranche'],

  }),
   getTotalNbreUseNbreActif: builder.query({
      query: (id_tranche) => ({
          url: `/numerotation/get_nb_use_actif_by_tranche/${id_tranche}`,
          method: "GET",
      }),
  }),
   getRessourcesSousTrancheParTranche: builder.query({
      query: (id_tranche) => ({
          url: `/numerotation/get_all_ressource_sous_tranche_by_tranche/${id_tranche}`,
          method: "GET",
      }),
  }),
   getOccupants: builder.query({
      query: (id_tranche) => ({
          url: `/numerotation/occupants`,
          method: "GET",
      }),
    providesTags: ['Occupants'],

  }),

  updateRessource: builder.mutation({
    query: (body) => ({
      url: `/numerotation/set_unavailable/${body.id_comp}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["RessourcesDispoTranche"],
  }),
  updateReserveRessource: builder.mutation({
    query: (body) => ({
      url: `/numerotation/set_reserved/${body.id_comp}`,
  
      method: "PUT",
      body,
    }),
    invalidatesTags: ["RessourcesDispoTranche"],
  }),
  updateLibererRessource: builder.mutation({
    query: (body) => ({
      url: `/numerotation/set_free/${body.id_comp}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["RessourcesDispoTranche"],
  }),

  // clients 
  createClients: builder.mutation({
    query: (body) => ({
      url: "/numerotation/client/create",
      method: "POST",
      body,
    }),
    invalidatesTags: ["Clients"],
  }),

  getClients: builder.query({
    query: () => ({
      url: "/numerotation/get_client",
      method: "GET",
    }),
    providesTags: ["Clients"],
  }),

  
  deleteClients: builder.mutation({
    query: (id) => ({
      url: `/numerotation/client/del/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Clients"],
  }),

  updateClients: builder.mutation({
    query: (body) => ({
      url: `/numerotation/client/update/${body.id_clt}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["Clients"],
  }),


  // ussd 
  createUssd: builder.mutation({
    query: (body) => ({
      url: "/numerotation/ussd/create",
      method: "POST",
      body,
    }),
    invalidatesTags: ["Ussd"],
  }),

  getUssd: builder.query({
    query: () => ({
      url: "/numerotation/ussd",
      method: "GET",
    }),
    providesTags: ["Ussd"],
  }),

  
  deleteUssd: builder.mutation({
    query: (id) => ({
      url: `/numerotation/ussd/del/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Ussd"],
  }),

  updateUssd: builder.mutation({
    query: (body) => ({
      url: `/numerotation/ussd/update/${body.id_ussd}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["Ussd"],
  }),

  getUssdListe: builder.query({
    query: (type) => ({
      url: `/numerotation/ussd_list/${type}`,
      method: "GET",
    }),
    providesTags: ["UssdListe"],
  }),
  getUssdBytype: builder.query({
    query: (type) => ({
      url: `numerotation/ussd/get_by_type/${type}`,
      method: "GET",
    }),
    providesTags: ["UssdListe"],
  }),

// ---------------Statut nums-------------------------
getStatutNum:builder.query({
  query: () => ({
    url: "/numerotation/statut_num",
    method: "GET",
  }),
  providesTags: ["Statut_num"],
}),

 // attribuer_ussd 

 createAttrUssd: builder.mutation({
  query: (body) => ({
    url: "/numerotation/attribuer_ussd/create",
    method: "POST",
    body,
  }),
  invalidatesTags: ["AttrUssd"],
}),

getAttrUssd: builder.query({
  query: () => ({
    url: "/numerotation/attribuer_ussd",
    method: "GET",
  }),
  providesTags: ["AttrUssd"],
}),


deleteAttrUssd: builder.mutation({
  query: (id) => ({
    url: `/numerotation/attribuer_ussd/del/${id}`,
    method: "DELETE",
  }),
  invalidatesTags: ["AttrUssd"],
}),

updateAttrUssd: builder.mutation({
  query: (body) => ({
    url: `/numerotation/attribuer_ussd/update/${body.id_attr_ussd}`,
    method: "PUT",
    body,
  }),
  invalidatesTags: ["AttrUssd"],
}),

getAttrUssdByUSSD: builder.query({
  query: (id_ussd) => ({
    url: `/numerotation/attribuer_ussd/${id_ussd}`,
    method: "GET",
  }),
  providesTags: ["AttrUssd"],
}),


// --------------Occupper-----------------

getbycomp: builder.query({
  query: (id_comp) => ({
    url: `/numerotation/get_by_comp/${id_comp}`,
    method: "GET",
  }),
  providesTags: ["Occuper"],
}),

getComp: builder.query({
  query: () => ({
    url: `/numerotation/occupants/`,
    method: "GET",
  }),
  providesTags: ["Occuper"],
}),


 // ****************************attribuer_mnc

 getMnc: builder.query({
  query: () => ({
    url: "/numerotation/mnc_list",
    method: "GET",
  }),
  providesTags: ["Mnc"],
}),

updateMnc: builder.mutation({
  query: (body) => ({
    url: `/numerotation/mnc/update/${body.id_mnc}`,
    method: "PUT",
    body,
  }),
  invalidatesTags: ["AttrMnc"],
}),

 createAttrMnc: builder.mutation({
  query: (body) => ({
    url: "/numerotation/attribuer_mnc/create",
    method: "POST",
    body,
  }),
  invalidatesTags: ["AttrMnc"],
}),

getAttrMnc: builder.query({
  query: () => ({
    url: "/numerotation/attribuer_mnc/",
    method: "GET",
  }),
  providesTags: ["AttrMnc"],
}),

deleteAttrMnc: builder.mutation({
  query: (id) => ({
    url: `/numerotation/attribuer_mnc/del/${id}`,
    method: "DELETE",
  }),
  invalidatesTags: ["AttrMnc"],
}),

updateAttrMnc: builder.mutation({
  query: (body) => ({
    url: `/numerotation/attribuer_mnc/update/${body.id_attr_mnc}`,
    method: "PUT",
    body,
  }),
  invalidatesTags: ["AttrMnc"],
}),

getAttrMncByUSSD: builder.query({
  query: (id_mnc) => ({
    url: `/numerotation/attribuer_mnc/${id_mnc}`,
    method: "GET",
  }),
  providesTags: ["AttrMnc"],
}),


 // ****************************attribuer_ispc

 getIspc: builder.query({
  query: () => ({
    url: "/numerotation/ispc_list",
    method: "GET",
  }),
  providesTags: ["Ispc"],
}),


 createAttrIspc: builder.mutation({
  query: (body) => ({
    url: "/numerotation/attribuer_ispc/create",
    method: "POST",
    body,
  }),
  invalidatesTags: ["AttrIspc"],
}),

getAttrIspc: builder.query({
  query: () => ({
    url: "/numerotation/attribuer_ispc/",
    method: "GET",
  }),
  providesTags: ["AttrIspc"],
}),

deleteAttrIspc: builder.mutation({
  query: (id) => ({
    url: `/numerotation/attribuer_ispc/del/${id}`,
    method: "DELETE",
  }),
  invalidatesTags: ["AttrIspc"],
}),

updateAttrIspc: builder.mutation({
  query: (body) => ({
    url: `/numerotation/attribuer_ispc/update/${body.id_attr_ispc}`,
    method: "PUT",
    body,
  }),
  invalidatesTags: ["AttrIspc"],
}),

getStat_sen: builder.query({
  query: () => ({
    url: "/numerotation/stat_sen",
    method: "GET",
  }),
  providesTags: ["Stat_sen"],
}),

// --------lot------------

getCompLot: builder.query({
  query: () => ({
    url: `/numerotation/lots/`,
    method: "GET",
  }),
  providesTags: ["CompoLot"],
}),

// getAttrCompLot: builder.query({
//   query: (id_comp) => ({
//     url: `/numerotation/get_attribuer_pq/${id_comp}`,
//     method: "GET",
//   }),
//   providesTags: ["AttrCompoLot"],

// }),
getAttrCompLot: builder.query({
  query: () => ({
    url: `/numerotation/get_attribuer_pq/`,
    method: "GET",
  }),
  providesTags: ["AttrCompoLot"],
}),

createNextLot: builder.mutation({
  query: (body) => ({
    url: "/numerotation/set_next_lot/",
    method: "POST",
    body,
  }),
  invalidatesTags: ["AttrCompoLot"],
}),


updateUseLot: builder.mutation({
  query: (body) => ({
    url: `/numerotation/update_nb_use_on_pq/`,
    method: "PUT",
    body,
  }),
  invalidatesTags: ["AttrCompoLot"],
}),

  }),




});

export const {
  useGetAttribuerServiceTrancheQuery,
  useCreateAttribuerServiceTrancheMutation,
  useUpdateAttribuerServiceTrancheMutation,
  useDeleteAttribuerServiceTrancheMutation,

  useGetServiceTrancheQuery,
  useCreateServiceTrancheMutation,
  useUpdateServiceTrancheMutation,
  useDeleteServiceTrancheMutation,

  useGetSousTrancheQuery,
  useCreateSousTrancheMutation,
  useUpdateSousTrancheMutation,
  useDeleteSousTrancheMutation,

  useGetTrancheQuery,
  useCreateTrancheMutation,
  useUpdateTrancheMutation,
  useDeleteTrancheMutation,

  useUpdateRessourceMutation,
  useUpdateReserveRessourceMutation,
  useUpdateLibererRessourceMutation,

  useGetAllRessourcesQuery,
  useGetRessourceDispoTrancheQuery,
  useGetRessourceListeSousTrancheQuery,
  useGetRessourceDispoServiceTrancheQuery,
  useGetRessourceDispoServiceQuery,
  useGetRessourceAllServiceQuery,
  useGetRessourceListeTrancheQuery,
  useGetRessourceOccupeTrancheQuery,
  useGetOccupantsQuery,

  useGetClientsQuery,
  useCreateClientsMutation,
  useUpdateClientsMutation,
  useDeleteClientsMutation,
  
  useGetUssdQuery,
  useCreateUssdMutation,
  useUpdateUssdMutation,
  useDeleteUssdMutation,
  useGetUssdListeQuery,
  useGetUssdBytypeQuery,

  useGetStatutNumQuery,

  useGetAttrUssdQuery,
  useGetAttrUssdByUSSDQuery,
  useCreateAttrUssdMutation,
  useUpdateAttrUssdMutation,
  useDeleteAttrUssdMutation,

  useGetbycompQuery,
  useGetCompQuery,

  useGetMncQuery,
  useUpdateMncMutation,
  useGetAttrMncQuery,
  useGetAttrMncByUSSDQuery,
  useCreateAttrMncMutation,
  useUpdateAttrMncMutation,
  useDeleteAttrMncMutation,

  useGetIspcQuery,
  useGetAttrIspcQuery,
  useCreateAttrIspcMutation,
  useUpdateAttrIspcMutation,
  useDeleteAttrIspcMutation,

  useGetStat_senQuery,

  useGetCompLotQuery,
  useGetAttrCompLotQuery,
  useCreateNextLotMutation,
  useUpdateUseLotMutation,
  useGetTotalNbreUseNbreActifQuery,
  useGetRessourcesSousTrancheParTrancheQuery
} = numerotationApiSlice;
