import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice.js";


import {
    useUpdatePlainteStatutMutation,
    useCreateObsPlainteMutation,
    useGetObsPlaintesQuery,
    useReturnInvesPlainteMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";
import { isEmpty } from "lodash";
export default function MotifDeRefus({
    display6,
    handleClose6,
    id_plt,
}) {
    const [editorContent, setEditorContent] = useState("");
    const [createObs, { isLoading: loadObsPlainte }] =
        useCreateObsPlainteMutation();
    const [updatePlainteStatut, { isLoading: loadUpdateState }] = useUpdatePlainteStatutMutation();
    const {data: observation = {}} = useGetObsPlaintesQuery(id_plt)
    const [createLogPage] = useCreateLogPageMutation();
    const [returnInvesPlainte] = useReturnInvesPlainteMutation();
    const [pieces, setPieces] = useState({images : []});

    const formData = new FormData();

    const handleFileChange = async (e) => {
        const selectedFiles = e.target.files;
    
        setPieces({...pieces, images : selectedFiles});
      };
    const handleChange = (event, editor) => {
        const content = editor.getData();
        setEditorContent(content);
    };

    
    const editorRef = useRef();

    useEffect(() => {
        if (!isEmpty(observation)) {
            setEditorContent(observation?.comment_obs)
        }
    }, [observation])

    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            return () => {
                editor.destroy();
            };
        }
    }, []);
    let [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (editorContent === "") {
            setDisabled(true);
        } else if (editorContent !== "") {
            setDisabled(false);
        }
    }, [ editorContent]);

    const createLogActionPlainte = async (status)=>{
        try {
          await createLogPage({content: "Renvoi en Investigation"}).unwrap();    
      } catch (error) {
        console.log('error', error)
      }
      }
    const upload = () => {
        handleClose6()
       
            Swal.fire({
                title: "Action irréversible !",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui", cancelButtonText: "Non",
            }).then(async (result) => {
                if (result.isConfirmed === true) {
for (let i = 0; i < pieces.images.length; i++) {
            formData.append("images[]", pieces.images[i]);
          }
          formData.append("motif", editorContent);
          formData.append("id_plt", id_plt);
          formData.append("id_stat_plt", 6);

          try {
            const retour_plainte2 = await returnInvesPlainte(formData).unwrap();
                    createLogActionPlainte()
                    if (retour_plainte2) {
                        window.location.href = "/toutes_les_plaintes";
                    }
          } catch (error) {
            console.error("Error :", error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Une erreur s'est produite, veuillez réessayer"
            });
          }
          
         }
            });
           
            // refetchPlaintes();
       
    };

    return (
        <div class="container">
            <Modal
                show={display6}
                onHide={handleClose6}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    <h5>Veuillez rajouter un motif du refus de d'investigation </h5>
                                    <Form.Group controlId="formBasicText">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={editorContent}
                                            onReady={(editor) => {
                                                // Store the editor instance in the ref
                                                editorRef.current = editor;
                                            }}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        multiple
                      style={{ marginTop: 12 }}

                      />


                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={disabled} onClick={upload} variant="success">
                        {" "}
                        {loadObsPlainte ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            <span>Valider </span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
