import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTokenApi: builder.query({
      query: () => ({
        url: "api/token",
        method: "GET",
      }),
    }),
    
  }),
});

export const {
  useGetTokenApiQuery,
  
} = userApiSlice;
