import { apiSlice } from "../api/apiSlice";

export const plateformeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlateforme: builder.query({
      query: () => ({
        url: "/plateform_auth",
        method: "GET",
      }),
      
    }),
    createPlateforme: builder.mutation({
      query: (body) => ({
        url: "/plateform/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Plateforme"],
    }),
    deletePlateforme: builder.mutation({
      query: (body) => ({
        url: `/plateform/del/${body.id_plateform}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Plateforme"],
    }),
  }),
});

export const {
  useGetPlateformeQuery,
  useCreatePlateformeMutation,
  useDeletePlateformeMutation,
} = plateformeApiSlice;
