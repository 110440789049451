import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableLIsteOR from "./dataTable/TableLIsteOR";
import { useGetObligationsQuery } from "../../redux/slice/obligationApiSlice";
import Spinner from "react-bootstrap/Spinner";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";

export default function NouvelleOB() {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [obg, setObg] = useState([]);
  const [expoData, setExpoData] = useState([]);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const { data: obligations = [], isLoading: loadObligation } =
    useGetObligationsQuery();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

// console.log("obligations", obligations)
  // const deleteCat = async (id) => {

  //   try {

  //     const confirmResult = await Swal.fire({
  //       title: "Êtes-vous sûr?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Oui, supprimer!"
  //     });

  //     if (confirmResult.isConfirmed) {

  //       // Supprimez réellement la catégorie
  //       await deleteData(id).unwrap();

  //       // Affichez une notification de succès
  //       Swal.fire({
  //         title: "SUCCES!",
  //         text: "Action réussie!",
  //         icon: "success"
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la suppression :", error);

  //     // Affichez une notification d'erreur
  //     Swal.fire({
  //       icon: "ERREUR",
  //       title: "Oops...",
  //       text: "Une erreur s'est produite lors de la suppression.",
  //     });
  //   } finally {
  //     // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
  //   }
  // };

  switchSkin(skin);
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de la liste des obligations réglémentaires"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  }
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    switchSkin(skin);
    if (!user?.id) {
      window.location.href = "/";
    }
    const retour = obligations.map((item) => {
      return {
        id_obligation_reg: item?.id_obligation_reg,
        lib_obligation_reg: item?.lib_obligation_reg,
        textes_ref: item?.textes_ref,
        id_type_obligation: item?.Type_obligation?.id_type_ob,
        id_type_obligation: item?.Type_obligation?.id_type_ob,
        secteur_obligation: item?.Secteur_obligation?.lib_secteur_obligation,
        type_obligation: item?.Type_obligation?.lib_type_ob,
        Type_operateur: item?.Type_operateur?.lib_type_op,
        poids: item?.poids
      };
    });
    const expo = obligations.map((item) => {
      return {
        secteur_obligation: item?.Secteur_obligation?.lib_secteur_obligation,
        categorie: item?.Categorie_obligation_reg?.lib_cat_obligation_reg,
        lib_obligation_reg: item?.lib_obligation_reg,
        ref: item?.textes_ref,
        article: item?.articles,
        Type_operateur: item?.Type_operateur?.lib_type_op,
        elt: item?.Element_ctrl?.lib_elt,
        periode: item?.Periodicite?.lib_periode,
        src: item?.Source_verification?.lib_source,
        echeance: item?.Echeance?.lib_echeance,
        poids: item?.poids,
        direction: item?.Directions.map(d => d.nom_direction).join(', ')
      };
    });

    setExpoData(expo);
    setObg(retour);
  }, [skin, obligations]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/nouvelle-obligation-reglmt">
                  Obligations Règlementaires
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nouvelle obligation
              </li>
            </ol>
            <h4 className="main-title mb-0">Ajouter nouvelle obligation</h4>
          </div>
        </div>

        <Row className="g-3">
          {[
            {
              bg: "primary",
              icon: "ri-survey-fill",
              value: "Nouveau",
              label: "Ajouter une nouvelle obligation",
              link: "/ajout-obligation-reglmt",
              modal: "non",
            },
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <NavLink
                to={item.modal === "oui" ? null : item.link}
                onClick={item.modal == "oui" ? handleShow : null}
              >
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-flex d-sm-block d-xl-flex align-items-center">
                      <div className={"helpdesk-icon text-white bg-" + item.bg}>
                        <i className={item.icon}></i>
                      </div>
                      <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                        <h2 className="card-value d-flex align-items-baseline mb-0">
                          {item.value}{" "}
                        </h2>
                        <label className="card-label fs-sm fw-medium mb-1">
                          {item.label}
                        </label>
                        <div className="mutual-badge"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          ))}
        </Row>

        <div className="container mt-5">
          <h4>Liste des obligations</h4>
        </div>

        {
          // loadObligation &&
          obg.length > 0 && (
            // <div className="w-100 d-flex flex-row align-iterms-center justify-content-center">
            //   <Spinner
            //       as="span"
            //       animation="border"
            //       size="md"
            //       role="status"
            //       aria-hidden="true"
            //     />
            // </div>

            <TableLIsteOR obg={obg}   expoData={expoData}/>
          )
        }

        {/* ----------------Modal confirmation archiver----------- */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Archiver une obligation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Etês-vous sûr d'archiver cette obligation</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Annuler
            </Button>
            <Button variant="danger" onClick={handleClose1}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
