import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slice/userSlice.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import Select from "react-select";


import {
  getStatutPlaintes,
  getTypePlaintes,
} from "../../redux/slice/plainteSlice";
import { getOperateurs } from "../../redux/slice/operateurSlice";

import {
  useGetDirectionsQuery,
  useUpdatePlainteStatutMutation,
  useCreateObsPlainteMutation,
  useCreateNotificationStepChangingMutation,
  useGetResultatObsQuery,
  useCreateInstPlainteMutation
} from "../../redux/apiSlice/plainteApiSlice.js";

export default function InstruirePlainte({ display5, handleClose5, id_plt, infOp }) {
  const [editorContent, setEditorContent] = useState("");
  const [endingContact, setEndingContact] = useState("");
  const [serviceError, setServiceError] = useState();
  const [sendNotification, { isLoading: loadNotif }] =
    useCreateNotificationStepChangingMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  const [destname, setDestName] = useState("");
  const [destid, setDestId] = useState(0);
  const [showFile, setShowFile] = useState(false);
  const [pieces, setPieces] = useState([]);

  const [createObs, { isLoading: loadObsPlainte }] =
    useCreateObsPlainteMutation();
  const [createInstPlainte, { isLoading: loadInstPlainte }] =
  useCreateInstPlainteMutation();
  const [updatePlainteStatut, { isLoading: loadUpdateState }] =
    useUpdatePlainteStatutMutation();
  const [step, setSteps] = useState(1);
  const [typeError, setTypeError] = useState();

  const operateurs = useSelector(getOperateurs);
  const [errors, setErrors] = useState({});
  let [disabled, setDisabled] = useState(true);
 
  const editorRef = useRef();

  const handleChange = (event, editor) => {
    const content = editor.getData()
    setEditorContent(content);
  };

  const {
    data: directions,
    refetch: refetchDirections,
    isLoading: loadDirections,
    isSuccess: isSuccessDirections,
  } = useGetDirectionsQuery();
  console.log("directions", directions);

  const statutPlaintes = useSelector(getStatutPlaintes);

  const createLogActionPlainte = async () => {
    try {
      await createLogPage({ content: "Instruction de Plainte" }).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    setPieces(selectedFiles);
  };

  const validateInsruction = async (complainStatusId, mycomplainId) => {
    handleClose5()
   
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

          const formData = new FormData();
          for (let i = 0; i < pieces.length; i++) {
            formData.append("images[]", pieces[i]);
          }
          formData.append("id_stat_plt", 5);
          formData.append("id_plt", id_plt);
          formData.append("id_dest", destid);
          formData.append("commentaire", editorContent);
          formData.append("type_dest", endingContact);

          try {
            const instruction = await createInstPlainte(formData).unwrap()
            if (instruction) {
              const retour_plainte = await updatePlainteStatut({
                id_stat_plt: 5,
                id_plt: id_plt,
              }).unwrap();
              createLogActionPlainte();
              if (retour_plainte) {
                sendNotification({
                  nouveau_status: statutPlaintes.find(
                    (etape) => etape.id_stat_plt === 6
                  ),
                });
                window.location.href = "/toutes_les_plaintes";
              }
            }
          } catch (error) {
            console.log("error", error)
          }
       
          
        }
      });
   
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        setSteps(2);
        break;
      case 2:
        validateInsruction();
        break;
    }
  };
  const connectVerify = useSelector(getUserInfo);
  

  useEffect(() => {
    if (!connectVerify?.id) {
      window.location.href = "/";
    }
    switch (step) {
      case 1:
        if (endingContact !== "") {
          setDisabled(false);
        } else if (endingContact === "") {
          setDisabled(true);
        }
        // setSteps(2);
        break;
      case 2:
        if (destname !== "") {
          setDisabled(false);
        } else if (destname === "") {
          setDisabled(true);
        }
        // setSteps(2);
        break;
    }
  }, [endingContact, destname, step]);

  const validateSelect = (value, setValidationError) => {
    const isValid = value !== "";
    setValidationError(isValid ? null : "Champ requis");
    setDestId(value);
  };

  const handleOperateurChange = (id) => {
    const operateur = id;
    validateSelect(operateur, setTypeError);
    const selectedOperator = operateurs.find(
      (operator) =>
        operator.id_operateur ===
        parseInt(operateur)
    );
    setDestName(
      selectedOperator?.nom_operateur
        ? selectedOperator.nom_operateur
        : ""
    );
    
};
  return (
    <div class="container">
      <Modal
        show={display5}
        onHide={() => {
          setSteps(1);
          setEndingContact("");
          setDestName("");
          setDestId(0);
          handleClose5();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Instruire Plainte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="row">
                {step == 1 && (
                  <div className="row">
                    <h5></h5>
                    <Form.Label >
                    Vers qui souhaitez-vous instruire la plainte ?
            </Form.Label>
                    <Form.Group controlId="formBasicText">
                      <Form.Check
                        type="radio"
                        label="Direction"
                        value="1"
                        checked={endingContact === "1"}
                        onChange={() => setEndingContact("1")}
                      />
                      <Form.Check
                        type="radio"
                        label="Opérateur"
                        value="0"
                        checked={endingContact === "0"}
                        onChange={(e) => {setEndingContact("0"); handleOperateurChange(infOp?.id_operateur)}}
                        style={{ marginBottom: 8 }}
                      />
                    </Form.Group>
                  
                  </div>
                )}

                {step == 2 && (
                  <>
                  
                  <div className="row px-5">
                    {endingContact === "0" && (
                      <div className="row">
                        
                        <Form.Label>
                        Opérateur
            </Form.Label>
            <Form.Control
              type="text"
              id="exampleFormControlInput1"
              value={infOp?.nom_operateur}
              disabled
            />
                        {/* <Select
                          options={operateurs.map((item) => {
                            return {
                              value: item.id_operateur,
                              label: item.nom_operateur,
                            };
                          })}
                          isSearchable={true}
                          onChange={handleOperateurChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "1px solid #1f365c",
                              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                              borderRadius: "5px",
                            }),
                          }}
                        />
                        {typeError && (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {typeError}
                          </span>
                        )} */}
                      </div>
                    )}
                    {endingContact == "1" && (
                      <div className="row">
                    
                        <Form.Label >
                        Veuillez sélectionner une direction
            </Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            validateSelect(e.target.value, setServiceError);
                            const selectedDirection = directions.find(
                              (direction) =>
                                direction.id_direction ===
                                parseInt(e.target.value)
                            );

                            setDestName(
                              selectedDirection?.nom_direction
                                ? selectedDirection?.nom_direction
                                : ""
                            );
                          }}
                        >
                          <option>Choisir</option>
                          {directions?.map((item, key) => {

                            let opt = <></>;
                            
                              opt = (
                                <option key={key} value={item.id_direction}>
                                  {item.nom_direction}
                                </option>
                              );
                        
                            return opt;
                          })}
                        </Form.Select>
                        {serviceError && (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {serviceError}
                          </span>
                        )}
                      </div>
                    )}
                     
                  </div>
                  <div className="row px-5 mt-5">
                  
                  <Form.Label>
                  Votre commentaire
            </Form.Label>
                   <CKEditor
                     editor={ClassicEditor}
                     data={editorContent}
                     onReady={(editor) => editorRef.current = editor}
                     onChange={handleChange}
                   />
         
                   <Form.Check
                     type="checkbox"
                     label="Cocher pour ajouter un fichier"
                     onChange={(e) => setShowFile(!showFile)}
                     style={{ marginTop: 10, marginBottom: 8 }}
                   />

                   {showFile && (
                     <Form.Control
                       type="file"
                       onChange={handleFileChange}
                       multiple
                     />
                   )}
                   </div>
                   </>
                )}


              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={
            step === 2 &&
            "col d-flex justify-content-between align-items-center"
          }
        >
          {step === 2 && (
            <Button onClick={() => setSteps(1)} variant="primary">
              Précédent
            </Button>
          )}
          <Button disabled={disabled} onClick={handleNext} variant="success">
            {" "}
            {step === 1 && (
              <div>
                {loadObsPlainte ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-
                    hidden="true"
                  />
                ) : (
                  <span> Suivant </span>
                )}{" "}
              </div>
            )}
            {step === 2 && (
              <div>
                {loadObsPlainte ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-
                    hidden="true"
                  />
                ) : (
                  <span> Valider </span>
                )}{" "}
              </div>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
