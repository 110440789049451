import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";

import { useGetListeMncQuery } from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

// import TableNumerotation from "./dataTables/TableNumerotation.js";
import { G } from "@react-pdf/renderer";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";

export default function ListePlainte() {
  const initEtatsMnc = {
    libre: [],
    occuper: [],
    reserver: [],
    en_instance: [],
    inutilisable: [],
    bloque: [],
  };
  const [lesEtatsMnc, setLesEtatsMnc] = useState({
    ...initEtatsMnc,
  });

  const { data: listeMnc = [] } = useGetListeMncQuery();

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Statistique mnc" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);
  useEffect(() => {
    setLesEtatsMnc({
      ...lesEtatsMnc,
      libre: listeMnc.filter((libr, key) => libr.id_stat_num == 1),
      occuper: listeMnc.filter((occ, key) => occ.id_stat_num == 4),
      reserver: listeMnc.filter((reser, key) => reser.id_stat_num == 3),
      en_instance: listeMnc.filter((inst, key) => inst.id_stat_num == 2),
      inutilisable: listeMnc.filter((inuti, key) => inuti.id_stat_num == 6),
      bloque: listeMnc.filter((bloq, key) => bloq.id_stat_num == 5),
    });
  }, [listeMnc]);

  const user = useSelector(getUserInfo);

  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique mnc</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "200px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row>
                        <Col sm="12" xl="12">
                          <label className="fs-sm fw-medium mb-2 mb-xl-1">
                            Nombre de ressources
                          </label>

                          <h4 className="finance-value">{listeMnc?.length}</h4>

                          <Row>
                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Réservée(s) :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {lesEtatsMnc.reserver?.length}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          (lesEtatsMnc.reserver?.length * 100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>

                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Libre(s) :
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length -
                                    (lesEtatsMnc.reserver?.length +
                                      lesEtatsMnc.occuper?.length +
                                      lesEtatsMnc.inutilisable?.length +
                                      lesEtatsMnc.en_instance?.length +
                                      lesEtatsMnc.bloque?.length)}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          ((listeMnc?.length -
                                            (lesEtatsMnc.reserver?.length +
                                              lesEtatsMnc.occuper?.length +
                                              lesEtatsMnc.inutilisable?.length +
                                              lesEtatsMnc.en_instance?.length +
                                              lesEtatsMnc.bloque?.length)) *
                                            100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>
                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  En instance(s) :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {lesEtatsMnc.en_instance?.length}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          (lesEtatsMnc.en_instance?.length *
                                            100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>
                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Attribuée(s) :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {lesEtatsMnc.occuper?.length}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          (lesEtatsMnc.occuper?.length * 100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>
                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Inutilisable(s) :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {lesEtatsMnc.inutilisable?.length}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          (lesEtatsMnc.inutilisable?.length *
                                            100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>
                            <Col xl="2" sm="12">
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Bloquée(s) :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {lesEtatsMnc.bloque?.length}
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  Taux :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {listeMnc?.length === 0
                                    ? 0 + "%"
                                    : parseFloat(
                                        (
                                          (lesEtatsMnc.bloque?.length * 100) /
                                          listeMnc?.length
                                        ).toFixed(4)
                                      ) + "%"}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableNumerotation tranche={dataTranche} />
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
