import React, { Fragment, useEffect, useState } from "react";

import { Button as Btn, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import "./styleNumerotation.css";
import Select from "react-select";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import {
  useGetUssdQuery,
  useCreateUssdMutation,
  useUpdateUssdMutation,
  useDeleteUssdMutation,
  useGetServiceTrancheQuery,
  useGetUssdListeQuery,
} from "../../redux/apiSlice/numerotationApiSlice";

const Ussd = () => {
  const [type, setType] = useState(1);
  const [typeUssd, setTypeUssd] = useState([
    { type: 1 },
    { type: 2 },
    { type: 3 },
    { type: 4 },
    { type: 5 },
    { type: 6 },
    { type: 7 },
    { type: 8 },
    { type: 9 },
  ]);
  const { data, isLoading } = useGetUssdQuery();
  const [createUssd] = useCreateUssdMutation();
  const [updateUssd] = useUpdateUssdMutation();
  const [deleteUssd] = useDeleteUssdMutation();
  const { data: services = [], isLoading: loadService } =
    useGetServiceTrancheQuery();
  const { data: listeUssd = [], isLoading: loadListeUssd } =
    useGetUssdListeQuery(type);
  const fileName = "ussd";
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const cols = [
    { field: "num", header: "N°", sortable: true },
    { field: "Ussd_list.libelle", header: "Ussd", sortable: true },
    { field: "desc_ussd", header: "Commentaire ", sortable: true },
    { field: "Service_tran.lib_service", header: "Service ", sortable: true },
    { field: "actions", header: "Actions", sortable: false },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const initForm = { id_list: "", desc_ussd: "", id_service: "" };

  const [skin, setSkin] = useState(currentSkin);
  const [search, setSearch] = useState("");
  const [form, setForm] = useState({ ...initForm });

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin, listeUssd]);
 
  const disabled =
    form.id_list_ussd === "" ;

  const reset = () => {
    setForm({ ...initForm })
    setType(1)
};

  const save = async () => {
    Swal.fire({
      title: "Gestion des ussd",
      text: "Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      try {
        if (result.isConfirmed === true) {
         
          if (form?.id_ussd) {
            await updateUssd(form).unwrap();
          } else {
            await createUssd(form).unwrap();
          }

          Swal.fire({
            title: "Félicitations!",
            text: `Ussd ${form?.id_ussd ? "modifié" : "crée"}`,
            icon: "success",
          });

          reset();
          window.location.reload()
        }
      } catch (error) {
      
        if (error.data.name == "SequelizeUniqueConstraintError") {
          Swal.fire({
            title: "Attention!",
            text: `Cet ussd existe déjà`,
            icon: "warning",
          });
        } else {
          Swal.fire({
            title: "Attention!",
            text: `Une erreur s'est produite ! Veuillez réessayer`,
            icon: "error",
          });
        }
      }
    });
  };

  const deleteData = (value) => {
    Swal.fire({
      title: "Gestion des ussd",
      text: "Voulez-vous vraiment supprimer l'ussd' ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await deleteUssd(value.id_ussd).unwrap();

        Swal.fire({
          title: "Félicitations!",
          text: `Ussd supprimée`,
          icon: "success",
        });

        reset();
        window.location.reload();
      }
    });
  };

  const exportCSV = (selectionOnly) => {
    // dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, data);
        doc.save(fileName + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const rowClassName = (rowData, index) => {
    return index !== data.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        <Btn
          onClick={() => setForm(rowData)}
          className="badge bg-success p-2 mr-1"
          style={{ borderRadius: 30 }}
        >
          <i className="ri-pencil-line  ri-2x"></i>
          <span></span>
        </Btn>

        <Btn
          onClick={() => deleteData(rowData)}
          className="badge bg-danger p-2"
          style={{ borderRadius: 30 }}
        >
          <i className="ri-delete-bin-6-line  ri-2x"></i>
          <span></span>
        </Btn>
      </div>
    );
  };

  const Head = () => (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche ..."
          />
        </div>
      </div>
      <div className="container_export">
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="secondary"
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          size="small"
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0"> Gestion des ussd </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">Liste des ussd</span>
            <hr style={{ width: "100%" }} />

            <div className="card" style={{ width: "100%" }}>
              <Tooltip target=".export-buttons>button" position="bottom" />
              <style>{`.table-row td {border-bottom: 1px solid #dee2e6;}`}</style>

              <DataTable
                value={data}
                header={<Head />}
                tableStyle={{ minWidth: "100%", fontSize: 12 }}
                globalFilter={search}
                globalFilterFields={["lib_tranche", "desc_tranche"]}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName}
                loading={isLoading}
              >
                {cols.map((col, index) => (
                  <Column
                    key={index}
                    field={col.field}
                    header={col.header}
                    sortable={col.sortable}
                    body={
                      col.field === "actions"
                        ? actionBodyTemplate
                        : col.field === "num"
                        ? (data, options) => options.rowIndex + 1
                        : null
                    }
                  />
                ))}
              </DataTable>
            </div>
          </div>

          <div className="second_container">
            <span className="titre_cat">Nouveau ussd</span>
            <hr style={{ width: "100%" }} />
            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form mb-3">
                  <div className="container_label">
                    <Form.Label>Type USSD</Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option>Choisir</option>
                      {typeUssd.map((type, i) => (
                        <option key={i} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                {
                    !loadListeUssd  &&
                    <div className="sous_container_form mb-3">
                  <div className="container_label">
                    <Form.Label htmlFor="lib">Ussd</Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Select
                    defaultValue={form.id_list !="" && [{
                          label: form?.Ussd_list?.libelle,
                          value: form?.Ussd_list?.id_list,
                    }] }
                      options={listeUssd.map((item) => {
                        return {
                          label: item.libelle,
                          value: item.id_list,
                        };
                      })}
                      isSearchable={true}
                      onChange={(v) =>
                        setForm({ ...form, id_list: v.value })
                      }
                    />
                  </div>
                </div>}

                <div className="sous_container_form mb-3">
                  <div className="container_label">
                    <Form.Label htmlFor="desc">Commentaire </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="desc"
                      value={form.desc_ussd}
                      onChange={(e) =>
                        setForm({ ...form, desc_ussd: e.target.value })
                      }
                    />
                  </div>
                </div>
                {/* <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label>Service</Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Select
                      value={form.id_service}
                      onChange={(e) =>
                        setForm({ ...form, id_service: e.target.value })
                      }
                    >
                      <option>Choisir</option>
                      {services.map((service, i) => (
                        <option key={i} value={service.id_service}>
                          {service.lib_service}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div> */}

                <div className="container_btn">
                  <Btn variant="primary" onClick={() => reset()}>
                    Annuler
                  </Btn>
                  <Btn
                    variant="danger"
                    disabled={disabled}
                    onClick={() => save()}
                  >
                    Enregistrer
                  </Btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Ussd;
