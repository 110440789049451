import { apiSlice } from "../api/apiSlice"

export const moduleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getModules: builder.query({
            query: () => ({
                url: '/module',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Modules', id: 'LIST' },
                    ...result.map(item => ({ type: 'Modules', id: item.id_mod }))
                ] : ['Modules']
            }
        }),

        // createEcheance: builder.mutation({
        //     query: body => ({
        //         url: '/obligation/echeance/create',
        //         method: 'POST',
        //         body
        //     }),
        //     invalidatesTags: ['Echeances']
        // }),

        // updateEcheance: builder.mutation({
        //     query: body => ({
        //         url: `/obligation/echeance/update/${body.id_echeance}`,
        //         method: 'PUT',
        //         body
        //     }),
        //     invalidatesTags: ['Echeances']
        // }),

        // deleteEcheance: builder.mutation({
           
        //     query: (id) => ({
        //       url: `/obligation/echeance/del/${id}`,
        //       method: 'DELETE',
        //     }),
        //     invalidatesTags: ['Echeances'],
        //   }),
    })
})

export const {
    useGetModulesQuery,
    // useCreateEcheanceMutation,
    // useUpdateEcheanceMutation,
    // useDeleteEcheanceMutation,
    
} = moduleApiSlice