import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tooltip as Tol, OverlayTrigger } from "react-bootstrap";
export default function TableCat(props) {
  const { ab, autreAffichage } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);
  var cols = [];
  switch (autreAffichage) {
    case "ab":
      cols = [
        { field: "tranche", header: "Tranche", sortable: true },
        { field: "ab", header: "Ab", sortable: true },
        { field: "lib_lot", header: "Libellé lot", sortable: true },
        { field: "debut_fin_lot", header: "Debut et fin lot", sortable: true },
        { field: "nb_actif", header: "Numéros actifs", sortable: true },
        { field: "taux_actif", header: "Taux numéro actif", sortable: true },
        { field: "nb_use", header: "Numéros utilisés ", sortable: true },
        { field: "taux_nb_use", header: "Taux d'utilisation", sortable: true },
      ];
      break;
    case "tranche":
      cols = [
        { field: "tranche", header: "Tranche", sortable: true },
        { field: "statut", header: "statut", sortable: true },
      ];
      break;
    default:
      break;
  }

  const exportColumns = cols.map((col) => ({
    //FFFF
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default();

        const title = "Tranches avec ab"; // Titre de votre document
        const tableStartY = 20; // Position de départ du tableau
        let isFirstPage = true; // Indicateur pour la première page

        // Configuration du tableau
        const tableConfig = {
          startY: tableStartY, // Position de départ du tableau
          theme: "grid", // Thème du tableau
          styles: {
            overflow: "linebreak", // Gérer le renvoi à la ligne automatique
          },
          columnStyles: {
            // Styles des colonnes
            // Ajoutez les styles pour chaque colonne si nécessaire
          },
          didDrawPage: (data) => {
            // Afficher le titre uniquement sur la première page
            if (isFirstPage) {
              doc.text(title, 14, 15);
              isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
            }

            // Gérer la position de départ du tableau pour le centrage
            const tableWidth = data.table.width;
            const pageWidth = doc.internal.pageSize.width;
            const x = (pageWidth - tableWidth) / 2;
            const y = tableStartY - 10;
            data.cursor.y = y;
            data.cursor.x = x;
          },
        };

        // Entêtes du tableau
        const header = exportColumns.map((col) => col.title);

        // Données du tableau
        const data = ab.map((row, rowIndex) =>
          exportColumns.map((col) => {
            return row[col.dataKey]; // Accéder aux données avec le champ de la colonne
          })
        );

        // Génération du tableau dans le PDF
        doc.autoTable(header, data, tableConfig);

        // Enregistrement du PDF
        doc.save("ab.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ab);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "ab");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="container_export">
        <OverlayTrigger placement={"top"} overlay={<Tol>Exportation PDF.</Tol>}>
          <Button
            type="button"
            icon="pi pi-file-pdf"
            rounded
            onClick={exportPdf}
            className=""
            data-pr-tooltip="PDF"
            style={{ borderRadius: 30 }}
            severity="danger"
            size="small"
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tol>Exportation EXCEL.</Tol>}
        >
          <Button
            type="button"
            icon="pi pi-file-excel"
            className=""
            severity="success"
            rounded
            onClick={exportExcel}
            data-pr-tooltip="Excel"
            style={{ borderRadius: 30 }}
            size="small"
          />
        </OverlayTrigger>
        <OverlayTrigger placement={"top"} overlay={<Tol>Exportation CSV.</Tol>}>
          <Button
            type="button"
            icon="pi pi-file"
            rounded
            onClick={() => exportCSV(false)}
            className=""
            data-pr-tooltip="CSV"
            style={{ borderRadius: 30 }}
            severity="secondary"
            size="small"
          />
        </OverlayTrigger>
      </div>
    </div>
  );

  const rowClassName = (rowData, index) => {
    return index !== ab.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={ab}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={10}
        rowClassName={rowClassName}
      >
        {cols.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            body={null}
          />
        ))}
      </DataTable>
    </div>
  );
}
