import { apiSlice } from "../api/apiSlice";

export const rapportPlainteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlainteSystem: builder.query({
      query: (body) => ({
        url: `plainte/get_between_dates/${body.debut}/${body.fin}`,
        method: "GET",
      }),
    }),
    getStatistiquePlainteGlobal: builder.query({
      query: ({
        lib_sexe,
        id_stat_plt,
        id_operateur,
        date_debut,
        date_fin,
      }) => ({
        url: `plainte/plainte/get_by_grouped/${lib_sexe}/${id_stat_plt}/${id_operateur}/${date_debut}/${date_fin}`,
        method: "GET",
      }),
    }),
    getPlainteAvecStatutDate: builder.query({
      query: (body) => ({
        url: `plainte/get_by_statut_between_dates/${body.id_stat_plt}/${body.debut}/${body.fin}`,
        method: "GET",
      }),
    }),
    getPlainteAvecOpDate: builder.query({
      query: (body) => ({
        url: `plainte/get_by_operateur_betwenn_dates/${body.id_operateur}/${body.debut}/${body.fin}`,
        method: "GET",
      }),
    }),
    getPlainteInstruiteATempsDate: builder.query({
      query: (body) => ({
        url: `/plainte/instruct_on_time_between_date/${body.debut}/${body.fin}`,
        method: "GET",
      }),
    }),
    getPlainteNonInstruiteATemps: builder.query({
      query: (body) => ({
        url: `/plainte/instruct_on_time`,
        method: "GET",
      }),
    }),
    getPlainteParSexe: builder.query({
      query: (body) => ({
        url: `plainte/get_by_sexe/${body.lib_sexe}`,
        method: "GET",
      }),
    }),
    getTotalRessourcesParTranche: builder.query({
      query: (body) => ({
        url: `/numerotation/get_all/tranche/${body.id_tranche}`,
        method: "GET",
      }),
    }),
    getRessourcesDispoParTranche: builder.query({
      query: (body) => ({
        url: `/numerotation/get_available/tranche/${body.id_tranche}`,
        method: "GET",
      }),
    }),
    getRessourcesOccupeeParTranche: builder.query({
      query: (body) => ({
        url: `/numerotation/get_busy/tranche/${body.id_tranche}`,
        method: "GET",
      }),
    }),
    getAllTranche: builder.query({
      query: (body) => ({
        url: "/numerotation/tranche",
        method: "GET",
      }),
    }),
    getObligationParCat: builder.query({
      query: (body) => ({
        url: `/obligation/get_by_cat/${body.id_cat_obligation_reg}`,
        method: "GET",
      }),
    }),
    getObligationParTypeOp: builder.query({
      query: (body) => ({
        url: `/obligation/get_by_type_op/${body.id_type_op}`,
        method: "GET",
      }),
    }),
    getObligationRespecterParOp: builder.query({
      query: (body) => ({
        url: `/obligation/get_respected_by_operator/${body.id_operateur}`,
        method: "GET",
      }),
    }),
    getObligationNonRespecterParOp: builder.query({
      query: (body) => ({
        url: `/obligation/get_not_respected_by_operator/${body.id_operateur}`,
        method: "GET",
      }),
    }),
    getPlainteAvecStatut: builder.query({
      query: (body) => ({
        url: `/plainte/get_by_statut/${body.id_stat_plt}`,
        method: "GET",
      }),
    }),
    getCatObligation: builder.query({
      query: (body) => ({
        url: `/obligation/cat_obligation`,
        method: "GET",
      }),
    }),
    getTypeObligation: builder.query({
      query: (body) => ({
        url: `/obligation/type_obligation/`,
        method: "GET",
      }),
    }),
    getTypeOperateur: builder.query({
      query: (body) => ({
        url: `/operateur/type_operateur/`,
        method: "GET",
      }),
    }),
    getOperateur: builder.query({
      query: (body) => ({
        url: `/operateur`,
        method: "GET",
      }),
    }),
    getAllPlainte: builder.query({
      query: (body) => ({
        url: "/plainte",
        method: "GET",
      }),
    }),
    getAllService: builder.query({
      query: (body) => ({
        url: "/plainte/service",
        method: "GET",
      }),
    }),
    getTypePlainte: builder.query({
      query: (body) => ({
        url: "/plainte/type_plainte",
        method: "GET",
      }),
    }),
    getAllDirection: builder.query({
      query: (body) => ({
        url: "/obligation/direction",
        method: "GET",
      }),
    }),
    getLesObligationsAffectee: builder.query({
      query: (body) => ({
        url: "/obligation_affected",
        method: "GET",
      }),
    }),
    getCanauxReception: builder.query({
      query: (body) => ({
        url: "/plainte/canal_plainte/",
        method: "GET",
      }),
    }),
    getObligationsParTypOp: builder.query({
      query: (body) => ({
        url: `/obligation/get_obligation_operateur_by_type_operateur/${body.id_type_op}`,
        method: "GET",
      }),
    }),
    getUssd: builder.query({
      query: (body) => ({
        url: `/numerotation/ussd`,
        method: "GET",
      }),
    }),
    getListeUssd: builder.query({
      query: (body) => ({
        url: `/numerotation/ussd_list`,
        method: "GET",
      }),
    }),
    getListeMnc: builder.query({
      query: (body) => ({
        url: `/numerotation/mnc_list`,
        method: "GET",
      }),
    }),
    getListeIspc: builder.query({
      query: (body) => ({
        url: `/numerotation/ispc_list`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPlainteSystemQuery,
  useGetPlainteAvecStatutDateQuery,
  useGetPlainteParSexeQuery,
  useGetPlainteAvecOpDateQuery,
  useGetPlainteInstruiteATempsDateQuery,
  useGetPlainteNonInstruiteATempsQuery,

  useGetTotalRessourcesParTrancheQuery,
  useGetAllTrancheQuery,
  useGetRessourcesDispoParTrancheQuery,
  useGetRessourcesOccupeeParTrancheQuery,

  useGetObligationParCatQuery,
  useGetObligationParTypeOpQuery,
  useGetObligationRespecterParOpQuery,
  useGetObligationNonRespecterParOpQuery,
  useGetCatObligationQuery,
  useGetOperateurQuery,
  useGetTypeObligationQuery,
  useGetTypeOperateurQuery,

  useGetStatistiquePlainteGlobalQuery,
  useGetPlainteAvecStatutQuery,
  useGetAllPlainteQuery,

  useGetAllServiceQuery,
  useGetTypePlainteQuery,

  useGetAllDirectionQuery,

  useGetLesObligationsAffecteeQuery,
  useGetCanauxReceptionQuery,
  useGetObligationsParTypOpQuery,
  useGetUssdQuery,
  useGetListeUssdQuery,
  useGetListeMncQuery,
  useGetListeIspcQuery,
} = rapportPlainteApiSlice;
