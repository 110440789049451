import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  label: {
    marginBottom: 4,
  },
});

const Rapport = ({ obsPlainte, resultObs, mycomplaint }) => (


  < Document >
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>Type de plainte</Text>
        <Text style={styles.label}>{mycomplaint?.Type_plainte.lib_type_plt}</Text>

        <Text style={styles.heading}>Opérateur Concerné</Text>
        <Text style={styles.label}>{mycomplaint?.Operateur?.nom_operateur}</Text>

        <Text style={styles.heading}>Service concerné</Text>
        <Text style={styles.label}>{mycomplaint?.service}</Text>

        <Text style={styles.heading}>Type de personne</Text>
        <Text style={styles.label}>
          {mycomplaint?.type_personne === 0 ? 'Personne physique' : 'Personne morale'}
        </Text>

        <Text style={styles.heading}>Email</Text>
        <Text style={styles.label}>{mycomplaint?.email_plt}</Text>

        <Text style={styles.heading}>Téléphone</Text>
        <Text style={styles.label}>{mycomplaint?.tel_plt}</Text>

        <Text style={styles.heading}>Adresse géographique</Text>
        <Text style={styles.label}>{mycomplaint?.adresse_plt}</Text>


        <Text wrap style={styles.heading}>
          Recours fait à l'operateur ?
        </Text>
        <Text>{mycomplaint?.recours_op === 0 ? 'Oui' : 'Non'}</Text>

        <Text wrap style={styles.heading}>
          Réponse de la part de l’opérateur ?
        </Text>
        <Text>{mycomplaint?.reponse_op === 0 ? 'Oui' : 'Non'}</Text>
      </View>

      <View style={styles.section}>


        <Text style={styles.heading}>
          Référence de la plainte déposée auprès de l’opérateur
        </Text>
        <Text>{mycomplaint?.ref_plt}</Text>

        <Text wrap style={styles.heading}>Résumé de la plainte</Text>
        <Text>{mycomplaint?.desc_plt}</Text>

        <Text style={styles.heading}>Date du premier constat</Text>
        <Text>{mycomplaint?.date_constat}</Text>

        <Text style={styles.heading}>Canal de retour souhaité</Text>
        <Text>
          {mycomplaint?.canal_retour === 'email' ? 'E-mail' : 'Téléphone'}
        </Text>

        <Text wrap style={styles.heading}>Commentaire de l'observation</Text>
        <Text wrap >
          {obsPlainte?.comment_obs}
        </Text>

        <Text wrap style={styles.heading}>Resultat de l'observation</Text>
        <Text wrap >
          {resultObs?.comment_res}
        </Text>


      </View>
    </Page>
  </Document >
);

export default Rapport;
