import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import Sidebar from "../layouts/Sidebar";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";
import { useGetPlaintesQuery } from "../redux/apiSlice/plainteApiSlice.js";
import DashCards from "./DashCards.js";
import { Skeleton } from "@mui/material";
import "./styleDash.css";
import moment from "moment";
import {
  useGetAllPlainteQuery,
  useGetLesObligationsAffecteeQuery,
  useGetListeIspcQuery,
  useGetListeMncQuery,
  useGetObligationsParTypOpQuery,
  useGetPlainteAvecStatutQuery,
  useGetTypeOperateurQuery,
  useGetUssdQuery,
} from "../redux/apiSlice/statistiquePlainteApiSlice.js";
import { useCreateLogPageMutation } from "../redux/apiSlice/logPageApiSlice.js";
import TableClassification from "../pages/statistique/obligation/dataTables/TableClassification.js";
import ShieldIcon from "@rsuite/icons/Shield";
import PageIcon from "@rsuite/icons/Page";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import DeviceIcon from "@rsuite/icons/Device";
import TextImageIcon from "@rsuite/icons/TextImage";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line, Pie, Doughnut, Bar, HorizontalBar } from "react-chartjs-2";
import {
  useGetAllRessourcesQuery,
  useGetTrancheQuery,
} from "../redux/apiSlice/numerotationApiSlice.js";
export default function Dashboard() {
 
  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
    isSuccess: isSuccessPlaintes,
  } = useGetPlaintesQuery();
  const { data: nombreStatut1 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 1,
  });
  const { data: nombreStatut2 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 2,
  });
  const { data: nombreStatut3 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 3,
  });
  const { data: nombreStatut4 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 4,
  });
  const { data: nombreStatut5 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 5,
  });
  const { data: nombreStatut6 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 6,
  });
  const { data: nombreStatut7 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 7,
  });
  const { data: nombreStatut8 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 8,
  });
  const { data: nombreStatut9 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 9,
  });
  const { data: nombreStatut10 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 10,
  });
  const { data: nombreStatut11 } = useGetPlainteAvecStatutQuery({
    id_stat_plt: 11,
  });

  const { data: listePlainte, isLoading: loadListePlainte } =
    useGetAllPlainteQuery();
  // graphe
  const { data: liste_ussd = [], isLoading: loadListe_ussd } =
    useGetUssdQuery();
  const { data: liste_ispc = [], isLoading: loadListe_ispc } =
    useGetListeIspcQuery();
  const { data: liste_mnc = [], isLoading: loadListe_mnc } =
    useGetListeMncQuery();
  const { data: ressources = [], isLoading: loadRessources } =
    useGetAllRessourcesQuery();
  const { data: tranches = [] } = useGetTrancheQuery();

  const user = useSelector(getUserInfo);
  
  useEffect(() => {
    if (!user || !user.id) {
      window.location.href = "/";
    }
  }, [user]); 
  

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Dashboard principal" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
  }, []);

  useEffect(() => {
    if (isSuccessPlaintes) {
      const selectedItems = plaintes;
      // Additional logic or state updates
      //setAccuse({ liste: selectedItems })
      localStorage.setItem(
        "plaintes",
        JSON.stringify({ liste: selectedItems })
      );
    }
  }, [isSuccessPlaintes, plaintes]);
  const nbrePlaintePreQualifiee = () => {
    if (listePlainte && listePlainte.length > 0)
      return listePlainte.filter((plt) => plt.id_type_req === null);
    return [];
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const repereIdTrancheEtFiltreEnFonctionDesStatut = (
    lib_tranche,
    id_statut
  ) => {
    const table_id = tranches
      .filter((tranch) =>
        [parseInt(lib_tranche)].includes(parseInt(tranch.lib_tranche))
      )
      .map((tranch) => tranch.id_tranche);
    return ressources.filter(
      (n) => n.id_stat_num === id_statut && n.tranche.id_tranche === table_id[0]
    ).length;
  };

  const recupereNbreEltAyantStatut = (arr, value) => {
    return arr.filter((n) => n.id_stat_num === value).length;
  };

  const { id: idStatut, lib: libelles } = {
    id: [1, 2, 3, 4, 5, 6],
    lib: [
      "Libre",
      "En instance",
      "Réservé",
      "Attribué",
      "Bloqué",
      "Inutilisable",
    ],
  };

  const mncData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_mnc, id)
  );
  const ussdData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_ussd, id)
  );
  const ispcData = idStatut.map((id) =>
    recupereNbreEltAyantStatut(liste_ispc, id)
  );

  const optionsUssd = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  const dataUssd = {
    labels: libelles,
    datasets: [
      {
        label: "USSD",
        data: ussdData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
      },
    ],
  };

  const optionsIspc = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  const dataIspc = {
    labels: libelles,
    datasets: [
      {
        label: "ISPC",
        data: ispcData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
      },
    ],
  };

  const optionsMnc = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  const dataMnc = {
    labels: libelles,
    datasets: [
      {
        label: "Mnc",
        data: mncData,
        backgroundColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],

        borderColor: [
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(199, 199, 199)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const optionsPnm = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const dataPnm = {
    labels: [
      "tranche 1",
      "tranche 2",
      "tranche 3",
      "tranche 4",
      "tranche 5",
      "tranche 6",
      "tranche 7",
      "tranche 8",
      "tranche 9",
    ],
    datasets: idStatut.map((id, index) => {
      const lib_tranche = libelles[id - 1];
      let backgroundColor = "";
      let borderColor = "";

      switch (lib_tranche) {
        case "Libre":
          backgroundColor = "rgb(75, 192, 192)";
          borderColor = "rgb(75, 192, 192)";
          break;
        case "En instance":
          backgroundColor = "rgb(54, 162, 235)";
          borderColor = "rgb(54, 162, 235)";
          break;
        case "Réservé":
          backgroundColor = "rgb(153, 102, 255)";
          borderColor = "rgb(153, 102, 255)";
          break;
        case "Attribué":
          backgroundColor = "rgb(255, 205, 86)";
          borderColor = "rgb(255, 205, 86)";
          break;
        case "Bloqué":
          backgroundColor = "rgb(255, 99, 132)";
          borderColor = "rgb(255, 99, 132)";
          break;
        case "Inutilisable":
          backgroundColor = "rgb(199, 199, 199)";
          borderColor = "rgb(199, 199, 199)";
          break;
        default:
          backgroundColor = "rgb(0, 0, 0)";
          borderColor = "rgb(0, 0, 0)";
      }

      return {
        label: lib_tranche,
        data: Array.from({ length: 9 }, (_, i) => {
          return repereIdTrancheEtFiltreEnFonctionDesStatut(
            (i + 1).toString(),
            id
          );
        }),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      };
    }),
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  // classement type op

  const { data: typeOp } = useGetTypeOperateurQuery();

  const recupeIdFrsAccesInternet = () => {
    const table_typ_op = typeOp
      ?.filter((typ) =>
        ["fournisseur d'accès internet"].includes(
          typ?.lib_type_op.toLowerCase()
        )
      )
      .map((typ) => typ?.id_type_op);
    if (table_typ_op && table_typ_op?.length > 0) return table_typ_op[0];
    return 0;
  };

  const { data: obligationAffectee } = useGetLesObligationsAffecteeQuery();
  const initOb = {
    id_cat_obligation_reg: "",
    id_operateur: "",
    id_type_ob: "",
    id_type_op: recupeIdFrsAccesInternet(),
    id_direction: "",
  };
  const [lesOb, setLesOb] = useState({ ...initOb });
  const [dataOb, setDataOb] = useState([]);

  const { data: obParTypOp } = useGetObligationsParTypOpQuery({
    id_type_op: lesOb.id_type_op !== 0 ? lesOb.id_type_op : 2,
  });

  const nbreObligationRespectee = (tableau, statut) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter((ob) => ob.statut === statut).length;
    }
    return 0;
  };

  const comparerDate = (dateVigeur, periodicite) => {
    const dateFuture = moment(dateVigeur)
      .add(parseInt(periodicite), "days")
      .format("YYYY-MM-DD");
    const dateAujourdhui = moment().format("YYYY-MM-DD");
    return moment(dateFuture).isBefore(dateAujourdhui);
  };

  const obligationEnCours = (tableau) => {
    if (tableau && tableau.length > 0) {
      return tableau.filter(
        (ob) =>
          comparerDate(
            ob?.date_vigueur,
            ob?.Obligation_reg?.Periodicite?.lib_periode
          ) === true
      ).length;
    }
    return 0;
  };

  const recupererInfo = (champ, value) => {
    setLesOb({ ...lesOb, [champ]: value });
  };
  let nbreObLieeTypOp = obParTypOp?.reduce(
    (total, op) => total + op?.Obligation_operateurs.length,
    0
  );

  const classementOp = () => {
    const infosSurObligationOp = obParTypOp?.map((valeur) => {
      let cumulePoid = valeur?.Obligation_operateurs?.reduce(
        (total, poid) => total + poid?.Obligation_reg?.poids,
        0
      );
      const resultatsDenominateurTauxConformite =
        nbreObLieeTypOp - obligationEnCours(valeur?.Obligation_operateurs);
      const tauxConformitee =
        resultatsDenominateurTauxConformite > 0
          ? (
              (nbreObligationRespectee(valeur?.Obligation_operateurs, 0) /
                resultatsDenominateurTauxConformite) *
              100
            ).toFixed(2)
          : 0;
      return {
        nom_operateur: valeur?.nom_operateur,
        nbre_totale_ob: nbreObLieeTypOp,
        nbre_ob: valeur?.Obligation_operateurs?.length,
        ob_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 1),
        ob_non_resp: nbreObligationRespectee(valeur?.Obligation_operateurs, 0),
        ob_en_cours: obligationEnCours(valeur?.Obligation_operateurs),
        nbre_total_poids: cumulePoid,
        taux_conformitee: tauxConformitee,
      };
    });
    const nonZeroObOperators = infosSurObligationOp?.filter(
      (op) => op.nbre_ob !== 0
    );

    nonZeroObOperators?.sort((a, b) => {
      if (a.nbre_total_poids === b.nbre_total_poids) {
        return b.ob_resp - a.ob_resp;
      }

      return b.nbre_total_poids - a.nbre_total_poids;
    });

    const updatedInfosSurObligationOp = nonZeroObOperators?.map((op, index) => {
      const suffix = index === 0 ? "er" : "em";
      return {
        ...op,
        rang: `${index + 1}${suffix}`,
      };
    });
    if (updatedInfosSurObligationOp?.length > 0)
      setDataOb(() => [...updatedInfosSurObligationOp]);
    else setDataOb([]);
  };
  useEffect(() => {
    classementOp();
  }, [obligationAffectee, obParTypOp]);
  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-2">
        <div className="d-md-flex flex-column">
          <h4 className="main-title mb-0">Tableau de Bord</h4>
        </div>
        <Row>
          {loadListePlainte && (
            <Col className="d-flex flex-column">
              <p>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <div className="d-flex flex-row" style={{ flexWrap: "wrap" }}>
                {[1, 2, 3, 4, 5].map((item, key) => (
                  <div key={key} style={{ margin: "10px 10px" }}>
                    <Skeleton height={140} width={180} />
                  </div>
                ))}
              </div>
            </Col>
          )}
          {!loadListePlainte && (
            <>
              <Col className="titre_compatiment">Plainte</Col>
              <Col sm="6" xl="12" className="disposition_card_dash">
                <DashCards
                  icon={
                    <ShieldIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Pré-qualification"
                  nbreElt={nbrePlaintePreQualifiee().length}
                  bordureBas="bleu"
                />
                <DashCards
                  icon={
                    <PageIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Plainte réceptionnée"
                  nbreElt={
                    nombreStatut2?.length > 0 ? nombreStatut2?.length : 0
                  }
                  bordureBas="bleu"
                />
                <DashCards
                  icon={
                    <DeviceIcon style={{ fontSize: "2.5rem", color: "blue" }} />
                  }
                  titreCard="Complétude d'info"
                  nbreElt={
                    nombreStatut4?.length > 0 ? nombreStatut4?.length : 0
                  }
                  bordureBas="jaune"
                />
                <DashCards
                  icon={
                    <TextImageIcon
                      style={{ fontSize: "2.5rem", color: "blue" }}
                    />
                  }
                  titreCard="Instruction"
                  nbreElt={
                    nombreStatut5?.length > 0 ? nombreStatut5?.length : 0
                  }
                  bordureBas="rouge"
                />
                <DashCards
                  icon={
                    <FolderFillIcon
                      style={{ fontSize: "2.5rem", color: "blue" }}
                    />
                  }
                  titreCard="Avis de règlement"
                  nbreElt={
                    nombreStatut10?.length > 0 ? nombreStatut10?.length : 0
                  }
                  bordureBas="bleu"
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="container_graphique">
          {loadListe_ussd && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_ussd && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_ussd">
                <span className="titre_compatiment">USSD</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_ussd">
                <Pie options={optionsUssd} data={dataUssd} />
              </div>
            </Col>
          )}

          {loadListe_ispc && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_ispc && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_ispc">
                <span className="titre_compatiment">ISPC</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_ispc">
                <Doughnut options={optionsIspc} data={dataIspc} />
              </div>
            </Col>
          )}
        </Row>
        <Row className="mb-2">
          {loadListe_mnc && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadListe_mnc && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_mnc">
                <span className="titre_compatiment">MNC</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_mnc">
                <Bar options={optionsMnc} data={dataMnc} />
              </div>
            </Col>
          )}

          {loadRessources && (
            <Col sm="6" xl="6" className="mb-2 d-flex flex-column">
              <p className="mb-2">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={20}
                  width={40}
                />
              </p>
              <Skeleton variant="rectangular" animation="wave" height={200} />
            </Col>
          )}
          {!loadRessources && (
            <Col sm="6" xl="6" className="d-flex flex-column mb-sm-4">
              <div className="d-flex flex-row justify-content-between cardre_titre_pnm">
                <span className="titre_compatiment">PNN</span>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>
              <div className="graphe_pnm">
                <Bar options={optionsPnm} data={dataPnm} />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm="12" xl="12" className="d-flex flex-column">
            <span className="titre_compatiment">
              Classement des opérateurs-Obligations réglementaires
            </span>
          </Col>
          <Col sm="12" xl="12" className="d-flex flex-column mb-2">
            <Form.Label htmlFor="typeOb">Type d'operateur:</Form.Label>

            <Form.Select
              id="typeOp"
              value={lesOb.id_type_op}
              onChange={(e) =>
                recupererInfo("id_type_op", parseInt(e.target.value))
              }
              style={{ width: "400px" }}
            >
              <option value="">Choisir</option>
              {typeOp?.map((typOp, key) => (
                <option key={key} value={typOp.id_type_op}>
                  {typOp.lib_type_op}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col sm="12" xl="12" className="d-flex flex-column">
            <TableClassification ob={dataOb} />
          </Col>
        </Row>
        {/* <Row className="g-3">
          <Col xl="8">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0 pb-3">
                <div className="finance-info p-3 p-xl-4">
                  <label className="fs-sm fw-medium mb-2 mb-xl-1">
                    Progression des plaintes
                  </label>
                  <h1 className="finance-value"><span>$</span>867,036.50 <span>USD</span></h1>
                  <h1 className="finance-value">
                    {listePlainte?.length > 0 ? listePlainte?.length : 0}
                  </h1>

                  

                  <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                    Liste totale des plaintes par année
                  </p>
                  
                  
                  <Row className="row-cols-auto g-3 g-xl-4 pt-2">
                    {[
                      {
                        amount: "30,342.15",
                        quarter: "First",
                        percent: "2.3",
                        status: "success",
                      },
                      {
                        amount: "48,036.90",
                        quarter: "Second",
                        percent: "6.8",
                        status: "success",
                      },
                      {
                        amount: "68,156.00",
                        quarter: "Third",
                        percent: "10.5",
                        status: "success",
                      },
                      {
                        amount: "64,896.65",
                        quarter: "Fourth",
                        percent: "0.8",
                        status: "danger",
                      },
                    ].map((item, index) => (
                      <Col key={index}>
                        <h6 className="card-value fs-15 mb-1">
                          ${item.amount} USD
                        </h6>
                        <span className="fs-sm fw-medium text-secondary d-block mb-1">
                          {item.quarter} Quarter
                        </span>
                        <span
                          className={
                            "fs-xs d-flex align-items-center ff-numerals text-" +
                            item.status
                          }
                        >
                          {item.percent}%{" "}
                          <i
                            className={
                              (item.status === "success"
                                ? "ri-arrow-up-line"
                                : "ri-arrow-down-line") + " fs-15 lh-3"
                            }
                          ></i>
                        </span>
                      </Col>
                    ))}
                  </Row>
                </div>

                <Nav as="nav" className="nav-finance-one p-3 p-xl-4">
                  <Link href="" className="active">
                    2024
                  </Link>
                  <Link href="">2023</Link>
                  <Link href="">2022</Link>
                </Nav>

                <ReactApexChart
                  series={seriesOne}
                  options={optionOne}
                  type="area"
                  height={430}
                  className="apex-chart-two"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="4" className="d-flex flex-column gap-2">
            <Row className="g-2">
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body className="overflow-hidden">
                    <h2 className="card-value mb-1">
                      {nombreStatut1?.length > 0 ? nombreStatut1?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte soumise
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut2?.length > 0 ? nombreStatut2?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte réceptionnée
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut3?.length > 0 ? nombreStatut3?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte classée
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="6">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut4?.length > 0 ? nombreStatut4?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en complétude d'information
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="6">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut5?.length > 0 ? nombreStatut5?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte instruite
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut6?.length > 0 ? nombreStatut6?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en investigation
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut7?.length > 0 ? nombreStatut7?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en résultat d'investigation
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut8?.length > 0 ? nombreStatut8?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en rapport d'investigation
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="g-2">
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut9?.length > 0 ? nombreStatut9?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en soumission d'investigation
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut10?.length > 0 ? nombreStatut10?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte en avis de règlement
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" xl="4">
                <Card className="card-one">
                  <Card.Body>
                    <h2 className="card-value mb-1">
                      {nombreStatut11?.length > 0 ? nombreStatut11?.length : 0}
                    </h2>
                    <p className="fs-xs text-secondary mb-0 lh-4">
                      Plainte traitée
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          
        </Row> */}

        <Footer />
      </div>
    </React.Fragment>
  );
}
