import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row,Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import TableAffectation from "./dataTable/TableAffectation";
import { useGetObligationsQuery } from "../../redux/slice/obligationApiSlice";
import { useGetTypeOperateursQuery } from "../../redux/slice/typeOperateurApiSlice";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";


export default function AffectationOR() {


  // modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  const {data: obligations = [], isLoading: loadObligation} = useGetObligationsQuery()
  const {data: typeOperateur = [], refetch: refetchTypeOp, isLoading: loadTypeObligations} = useGetTypeOperateursQuery()
  const {data: operateur = [], refetch: refetchOp, isLoading: loadOperateur} = useGetOperateursQuery()
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();


// console.log("obligations", obligations)
// console.log("typeOperateur", typeOperateur)
// console.log("operateur", operateur)


  switchSkin(skin);
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page d'affectation d'obligation réglémentaire"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  }
  const user = useSelector(getUserInfo);
  useEffect(() => {
    switchSkin(skin);
    if (!user?.id) {
      window.location.href = "/";
    }
    createLog()

  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><NavLink to="/dashboard-obligation-reglmt" >Obligations Règlementaires</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">Affectation</li>
            </ol>
            <h4 className="main-title mb-0">Affectation d'obligation règlementaire</h4>
          </div>

        </div>

        {
         
    obligations.length > 0 && typeOperateur.length >0 &&  operateur.length >0 &&
<TableAffectation
obligations={obligations}
typeOperateur={typeOperateur}
operateur={operateur}
refetchOp={refetchOp}
refetchTypeOp={refetchTypeOp}
/>

// <div className="w-100 d-flex flex-row align-iterms-center justify-content-center">
// <Spinner
//     as="span"
//     animation="border"
//     size="md"
//     role="status"
//     aria-hidden="true"
//   />
// </div>
        }


                        <Footer />
                      </div>
                    </React.Fragment>
                    )
}