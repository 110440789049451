import { apiSlice } from "../api/apiSlice";

export const faqApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query({
      query: () => ({
        url: "/renseignement_auth",
        method: "GET",
      }),
    }),
    createFaq: builder.mutation({
      query: (body) => ({
        url: "/renseignement/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Faq"],
    }),
    updateFaq: builder.mutation({
      query: (body) => ({
        url: `/renseignement/update/${body.id_rens }`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Faq"],
    }),
    deleteFaq: builder.mutation({
      query: (body) => ({
        url: `/renseignement/del/${body.id_rens}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Faq"],
    }),
  }),
});

export const {
  useGetFaqQuery,
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = faqApiSlice;
