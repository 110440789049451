import { apiSlice } from "../api/apiSlice"

export const profilApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProfil: builder.query({
            query: () => ({
                url: '/profil',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Profil', id: 'LIST' },
                    ...result.map(item => ({ type: 'Profil', id: item.id_profil }))
                ] : ['Profil']
            }
        }),

        getProfilById: builder.query({
            query: (id) => ({
                url: `/profil/${id}`,
                method: "GET",
            }),
            providesTags: ['Profil']
        }),
    
        createProfil: builder.mutation({
            query: body => ({
                url: '/profil/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Profil']
        }),

        deleteProfil: builder.mutation({
           
            query: (id) => ({
              url: `/profil/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Profil'],
          }),
    })
})

export const {
    useCreateProfilMutation,
    useGetProfilQuery,
    useGetProfilByIdQuery,
    useDeleteProfilMutation,
    
} = profilApiSlice