import { apiSlice } from "../api/apiSlice"

export const operateurApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOperateurs: builder.query({
            query: () => ({
                url: '/operateur',
                method: "GET",
            }),
            transformResponse: response => response.data,
            providesTags: (result) => {
                return result ? [
                    { type: 'Operateurs', id: 'LIST' },
                    ...result.map(item => ({ type: 'Operateurs', id: item.id_operateur }))
                ] : ['Operateurs']
            }
        }),
        createOperateurs: builder.mutation({
            query: body => ({
                url: '/operateur/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Operateurs']
        }),

        updateOperateurs: builder.mutation({
            query: body => ({
                url: `/operateur/update/${body.id_operateur}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Operateurs']
        }),

       
        deleteOperateurs: builder.mutation({
           
            query: (id) => ({
              url: `operateur/del/${id}`,
              method: 'DELETE',
            }),
            invalidatesTags: ['Operateurs'],
          }),
    })
})

export const {
    useGetOperateursQuery,
    useCreateOperateursMutation,
    useUpdateOperateursMutation,
    useDeleteOperateursMutation,
} = operateurApiSlice