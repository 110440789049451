import { createSlice } from "@reduxjs/toolkit";
import { plainteApiSlice } from "../apiSlice/plainteApiSlice";

const plainteSlice = createSlice({
    name: 'plainte',
    initialState: {
        liste: [],
        hebdomadaire: [],
        long_terme: [],
        statut_plaintes: [],
        type_plaintes: [],
    },
    extraReducers:  builder => {
        builder.addMatcher(plainteApiSlice.endpoints.getPlaintes.matchFulfilled, (state, { payload }) => {
            state.liste = payload.liste
            state.hebdomadaire = payload.hebdomadaire
        }).addMatcher(plainteApiSlice.endpoints.getTypePlaintes.matchFulfilled, (state, { payload }) => {
            state.type_plaintes = payload
        }).addMatcher(plainteApiSlice.endpoints.getStatutPlaintes.matchFulfilled, (state, { payload }) => {
            state.statut_plaintes = payload
        })
    }
})


export default plainteSlice.reducer

export const getPlaintes = state => state.plainte.liste
export const getTypePlaintes = state => state.plainte.type_plaintes
export const getStatutPlaintes = state => state.plainte.statut_plaintes