import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import {
  useUpdatePlainteStatutMutation,
  useCreateObsPlainteMutation,
  useGetObsPlaintesQuery,
  useUpdateObsPlainteMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { isEmpty } from "lodash";
export default function ComplementInformation({
  display,
  handleClose,
  id_plt,
  id_stat_plt
}) {
  const [createObs, { isLoading: loadObsPlainte }] =
    useCreateObsPlainteMutation();
  const [updatePlainteStatut, { isLoading: loadUpdateState }] =
    useUpdatePlainteStatutMutation();

  const { data: observationPlainte = {}, isLoading: loadObservationPlainte, isSuccess } = useGetObsPlaintesQuery(id_plt);

  const [updateObsPlainte, { isLoading: loadObsUpdate }] = useUpdateObsPlainteMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  const [editorContent, setEditorContent] = useState("");
  const [files, setFiles] = useState([]);
  const formData = new FormData();
  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;

    setFiles(selectedFiles);
    // changePlainteData("images", selectedFiles);
  };
  const handleChange = (event, editor) => {
    const content = editor.getData();
    setEditorContent(content);
  };

  const editorRef = useRef();

  useEffect(() => {
    if (!loadObservationPlainte && isSuccess) setEditorContent(!isEmpty(observationPlainte) ? observationPlainte.comment_obs : "")
  }, [loadObservationPlainte, isSuccess])


  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;

      return () => {
        editor.destroy();
      };
    }
  }, []);
  let [disabled, setDisabled] = useState(true);

  const createLogActionPlainte = async ()=>{
    try {
      await createLogPage({content: "Renvoie en Complétude d'information d'une Plainte"}).unwrap();    
  } catch (error) {
    console.log('error', error)
  }
  }
  const upload = () => {
    handleClose()
    formData.append("comment_obs", editorContent);
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
    }
    formData.append("id_plt", id_plt);

    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const retour_plainte2 = await updatePlainteStatut({
            id_stat_plt: 4 ,
            id_plt: id_plt,
            commentaire : editorContent
          }).unwrap();
          createLogActionPlainte()
          // if (retour_plainte2) {
          //   let retour_update, retour_plainte;

          //   if (observationPlainte?.id_obs) {
             
          //     retour_update = updateObsPlainte({
          //       id_obs: observationPlainte?.id_obs,
          //       comment_obs: editorContent,
          //     }).unwrap();
          //   } else {
          //     retour_plainte = await createObs(formData).unwrap();
          //   }

          //   if (retour_update || retour_plainte) window.location.href = "/toutes_les_plaintes";
          // }
          if (retour_plainte2) window.location.href = "/toutes_les_plaintes";

        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error creating obs state:", error);
    }
  };

  return (
    <div class="container">
      <Modal
        show={display}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="mb-2">
                  <h5>Veuillez rajouter un commentaire à cette plainte</h5>
                  <Form.Group>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      onReady={(editor) => {
                        // Store the editor instance in the ref
                        editorRef.current = editor;
                      }}
                      onChange={handleChange}
                      /*  onBlur={handleBlur}
                    onFocus={handleFocus} */
                    />
                  </Form.Group>

                  {/* <Form.Group controlId="formBasicText">
                    <Form.Label>Fichier(s)</Form.Label>
                    <Form.Control
                      onChange={handleFileChange}
                      type="file"
                      id="exampleFormControlInput1"
                      placeholder=""
                      name="images"
                      multiple
                    />
                  </Form.Group> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={editorContent === ""}
            onClick={upload}
            variant="success"
          >
            {" "}
            {loadObsPlainte ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Valider </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
