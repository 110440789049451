import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableQualification from "./dataTables/TableQualification.js";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slice/userSlice";
import Spinner from "react-bootstrap/Spinner";
import TableDetailsQualification from "./TableDetailsQualification.js";
import Swal from "sweetalert2";
import {
  useGetServicesQuery,
  useGetPlaintesQuery,
  useGetTypePlaintesQuery,
  useUpdatePlainteMutation,
  useGetTypeRequeteQuery,
  useGetProblematiqueQuery
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";

export default function Qualification() {

  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
    useUpdatePlainteMutation();

  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
    isSuccess: isSuccessPlaintes,
  } = useGetPlaintesQuery();

  const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
    useGetTypePlaintesQuery();

  const { data: typeRequete = [], isLoading: loadTypeRequete } =
    useGetTypeRequeteQuery();

  const { data: operateurs = [] } =
    useGetOperateursQuery();

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

    const {
      data: services,
      refetch: refetchServices,
      isLoading: loadServices,
      isSuccess: isSuccessServices
    } = useGetServicesQuery();

  
    const {
      data: problematique,
      refetch: refetchProb,
      isLoading: loadProb,
      isSuccess: isSuccessProb
    } = useGetProblematiqueQuery();

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  
  const [mycomplaint, setComplaint] = useState();
  const [upOpearteur, setUpOpearteur] = useState(false);
console.log("mycomplaint", mycomplaint)
  const [etatReq, setEtatReq] = useState(false);
  const [seeFonction, setSeeFonction] = useState(true);
  const [serviceState, setServiceState] = useState([]);
  const [problematiqueState, setProblematiqueState] = useState([]);

    const init = {
      id_type_req : "",
      id_type_plt : "",
      id_service : "",
      id_operateur : "",
      id_prob : "",
    }
    const handleShow = (complaint) => {
      setComplaint(complaint);
      setShow(true);
    };
  
  const [qualif, setQualif] = useState({...init});

  const changeQualif =(field, value)=>{

    if (field == "id_type_req" && value == 2 ) {
      setEtatReq(true)
      setQualif({...qualif, [field] : value})

    } else if (field == "id_type_plt" || field == "id_service" || field == "id_prob") {
      setEtatReq(true)
      setQualif({...qualif, [field] : value})
    } else {
      setEtatReq(false)
      setQualif({...qualif, [field] : value})
    }

  }
  
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
 
  const handleOperateurChange = (value) => {
    const operateur = value.value;
    setQualif({...qualif, id_operateur : operateur});
};

const save = async () => {
  if (upOpearteur && qualif.id_operateur == "") {
    Swal.fire({
      title: "Attention!",
      text: `Veuillez choisir un opérateur`,
      icon: "error"
  });
  } else {
    Swal.fire({
      title: "Qualification",
      text: "Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
  }).then(async (result) => {
 
      try {
          if (result.isConfirmed === true) {
            
             let data 
             if (upOpearteur) {
    
              if (etatReq == true) {
            
                data ={ ...mycomplaint,
                  id_plt : mycomplaint?.id_plt,
                  id_type_req : parseInt(qualif.id_type_req),
                  id_type_plt : parseInt(qualif.id_type_plt),
                  id_service : parseInt(qualif.id_service),
                  id_operateur : parseInt(qualif.id_operateur),
                  id_prob : parseInt(qualif.id_prob),
                  }
                
              } else {
               
                data ={...mycomplaint,
                  id_plt : mycomplaint?.id_plt,
                  id_type_req : parseInt(qualif.id_type_req),
                  id_operateur : parseInt(qualif.id_operateur),

                    }
               
              }
              
             } else {
            
              if (etatReq == true) {
                data ={...mycomplaint,
                  id_plt : mycomplaint?.id_plt,
                  id_type_req : parseInt(qualif.id_type_req),
                  id_type_plt : parseInt(qualif.id_type_plt),
                  id_service : parseInt(qualif.id_service),
                  id_prob : parseInt(qualif.id_prob), 
                
                 }
               
              } else {
                data ={...mycomplaint,
                  id_plt : mycomplaint?.id_plt,
                  id_type_req : parseInt(qualif.id_type_req),
                    }
              }
             }

                  await updatePlainte(data).unwrap()
    
  
              Swal.fire({
                  title: "Succes !!!",
                  text: `Qualification réussie!`,
                  icon: "success"
              });
              setQualif({...init})
              window.location.href = "/qualification"
          
          }
      } catch (error) {
          console.log('error', error)
          Swal.fire({
            title: "Attention!",
            text: `Une erreur s'est produite ! Veuillez réessayer`,
            icon: "error"
        });
       
        }
          
     
  });
  }
  
}

const checkDisable = () => {
 let result 
  switch (etatReq) {
    case true:
     
    if (
      qualif.id_type_req !== "" &&
      qualif.id_type_plt !== "" &&
      qualif.id_service !== "" &&
      qualif.id_canal !== "" 
    ) {
      result = false
    } else if (
     
      qualif.id_type_req == "" ||
      qualif.id_type_plt == "" ||
      qualif.id_service == "" ||
      qualif.id_canal == "" 
    ) {
      result = true

    }
      break;
    case false:

    if (
      qualif.id_type_req !== "" 
    ) {
    
      result = false
    } else if (
      qualif.id_type_req == "" 
    ) {
     
      result = true

    }

      break;
   
    default:
     
      break;
  }

  return result
}

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de préqualification de plainte"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  }

  const connectVerify = useSelector(getUserInfo);
  
  useEffect(() => {
    if (!connectVerify?.id) {
      window.location.href = "/";
    }
    createLog()
    refetchPlaintes();
  
    if (localStorage.getItem("token") === "") {
      window.location.href = "/";
    }
    if (connectVerify?.profil == "Administrateur" || connectVerify?.profil =="Super Administrateur") {
      setSeeFonction(true)
    } else {
      setSeeFonction(false)
    }


  }, []);


  useEffect(() => {
    
    if (isSuccessPlaintes) {
      const selectedItems = plaintes;
      localStorage.setItem(
        "plaintes",
        JSON.stringify({ liste: selectedItems })
      );
    }
  }, [isSuccessPlaintes, plaintes]);

  const chargementDesSelectListe =(lib, value)=>{
   
    switch (lib) {
      case  "id_type_plt":
        changeQualif("id_type_plt", value)
        const tableauService = services.filter((item)=>(item.id_type_plt === parseInt(value)))
        setServiceState(tableauService)
        setProblematiqueState([])
        break;

      case  "id_service":
        changeQualif("id_service", value)
        const tableauProblematique = problematique.filter((item)=>(item.id_service === parseInt(value)))
        setProblematiqueState(tableauProblematique)
        break;
    
      default:
        break;
    }
  }
 
  const changeCheck = (value)=>{
  if (value == true ) {
    setUpOpearteur(true)
  } else {
    setQualif({...qualif, id_operateur : ""})
    setUpOpearteur(false)
  }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (loadPlaintes) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // 100% of the viewport height
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  }

  if (!plaintes) {
    return <div>Error fetching data</div>;
  }

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des requêtes
              </li>
            </ol>
            <h4 className="main-title mb-0">Liste des requêtes</h4>
          </div>
        </div>

        <div>
          <TableQualification
            handleclose={handleClose}
            handleshow={handleShow}
            plaintes={plaintes}
          />
        </div>
        {/* ----------------Modal de détails----------- */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails requête</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              
            </div>
            <TableDetailsQualification mycomplaint={mycomplaint} />
           
          </Modal.Body>
         { seeFonction  &&
         <Modal.Footer>
         
          <div className="container row ">
            <Form.Check
            type="checkbox"
            label="Modifier l'opérateur"
            onChange={(e) => changeCheck(e.target.checked)}
          />
            </div>
            <div className="container row">
<div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Catégorie requête:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_type_req", e.target.value)}
          >
            <option>Choisir</option>
            {typeRequete?.map((item, key) => {
 
             return    ( <option key={key} value={item.id_type_req}>
                    {item.lib_type_req}
                  </option>)
                
            })}
          </Form.Select>
          
</div>

  
{upOpearteur &&
  <div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Opérateur 
          </Form.Label>     

          <Select 
 options={operateurs.map((item)=>{
   return{
     value : item.id_operateur,
     label : item.nom_operateur
   }
 })} 
 isSearchable={true} 
 onChange={handleOperateurChange}
/>
          
</div>
}


            </div>
            <div className="container row">
              {
               etatReq &&
               <>
               <div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Catégorie plainte:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>chargementDesSelectListe("id_type_plt", e.target.value)}
          >
            <option>Choisir</option>
            {typePlaintes?.map((item, key) => {
 
             return    ( <option key={key} value={item.id_type_plt}>
                    {item.lib_type_plt}
                  </option>)
                
            })}
          </Form.Select>
          
</div>
               <div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Service:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>chargementDesSelectListe("id_service", e.target.value)}
          >
            <option>Choisir</option>
            {serviceState?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_service}>
                    {item.lib_service}
                  </option>)
                
            })}
          </Form.Select>
          
</div>

<div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Problématique:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_prob", e.target.value)}
          >
            <option>Choisir</option>
            {problematiqueState?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_prob}>
                    {item.lib_prob}
                  </option>)
                
            })}
          </Form.Select>
          
</div>
               </> 
              }
            </div>
            <div className="container row ">
            <Button
         disabled={checkDisable()}
            variant="success"
            onClick={()=>save()}
          >
            Valider
          </Button>
            </div>
          </Modal.Footer>
          }
        
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
