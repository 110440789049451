import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import {  Tooltip as Tol, OverlayTrigger, Form, Button as Btn} from "react-bootstrap";
import { getUserInfo } from "../../../redux/slice/userSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";


export default function TableListeRequetes(props) {

  const [products, setProducts] = useState([]);
  const [intermediaire, setIntermediaire] = useState([]);
  const [date_debut, setDate_debut] = useState("");
  const [date_fin, setDate_fin] = useState("");

  const { plaintes, setPlainteData } = props;

  const formatDate = (timestamp) => {

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
        return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();


    return formattedDate;
};

const userInfo = useSelector(getUserInfo);

  useEffect(() => {

    const tab = plaintes?.liste.filter((item)=>( item.id_type_req != null && item.id_type_req != 2 && item.id_type_plt == null && item.id_service ==null && item.is_excel == 0 ))

    const operateur_plainte = tab.filter((item)=>parseInt(item.id_operateur) == parseInt(userInfo.id_operateur))

    const rsul = userInfo.profil == "Administrateur" || userInfo.profil =="Super Administrateur" ? tab : operateur_plainte

  
    const updatedPlaintesFront = rsul.map((plainte) => {
      
      const timestamp = typeof plainte.date_plt === 'string' ? parseInt(plainte.date_plt, 10) : plainte.date_plt;
  
      // Utilisez le nom correct pour le timestamp
      const dat = formatDate(timestamp);
  
      return {
          code: plainte?.num_suivi_plt,
          // cat: plainte?.Type_requete?.lib_type_req,
          op: plainte?.Operateur?.nom_operateur,
          dt: dat,
          // dt: plainte.date_constat,
          pl: plainte?.nom_plt+" "+plainte?.prenom_plt,
          id_stat_plt: plainte?.id_stat_plt,
          ref: plainte?.ref_plt,
          st: plainte?.Statut_plainte?.libelle,
          cd: plainte?.Statut_plainte?.id_stat_plt,
          user: plainte?.User?.nom_user,
          actions: true,
      };
  });
  
  // console.log("updatedPlaintesFront", updatedPlaintesFront)
    setProducts(updatedPlaintesFront);
    setIntermediaire(updatedPlaintesFront)
   
  }, [plaintes?.liste]);
  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);

  const cols = [
    { field: "code", header: "Code", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date de soumission", sortable: true },
    { field: "pl", header: "identité du plaignant", sortable: true },
    { field: "user", header: "User", sortable: true },
    // { field: "ref", header: "Réf. opérateur", sortable: true },
    // { field: "st", header: "statut", sortable: true },
    { field: "actions", header: "Actions", sortable: false }, // Nouvelle colonne pour les actions
  ];
  const colsExpo = [
    { field: "num", header: "N°", sortable: true },
    { field: "code", header: "Code", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date de soumission", sortable: true },
    { field: "pl", header: "identité du plaignant", sortable: true },
    { field: "user", header: "User", sortable: true },
  ];

  const exportColumns = colsExpo.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));


  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };


 
  const exportPdf = () => {
    
    import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
            const doc = new jsPDF.default();

            const title = "Liste des autres requêtes"; // Titre de votre document
            const tableStartY = 20; // Position de départ du tableau
            let isFirstPage = true; // Indicateur pour la première page

            // Configuration du tableau
            const tableConfig = {
                startY: tableStartY, // Position de départ du tableau
                theme: 'grid', // Thème du tableau
                styles: {
                    overflow: 'linebreak', // Gérer le renvoi à la ligne automatique
                },
                columnStyles: { // Styles des colonnes
                    // Ajoutez les styles pour chaque colonne si nécessaire
                },
                didDrawPage: (data) => {
                    // Afficher le titre uniquement sur la première page
                    if (isFirstPage) {
                        doc.text(title, 14, 15);
                        isFirstPage = false; // Mettre à jour l'indicateur pour les pages suivantes
                    }

                    // Gérer la position de départ du tableau pour le centrage
                    const tableWidth = data.table.width;
                    const pageWidth = doc.internal.pageSize.width;
                    const x = (pageWidth - tableWidth) / 2;
                    const y = tableStartY - 10;
                    data.cursor.y = y;
                    data.cursor.x = x;
                },
            };

            // Entêtes du tableau
            const header = exportColumns.map((col) => col.title);

              // Données du tableau
              const data =products.map((row, rowIndex) => exportColumns.map((col) => {
               
                if (col.dataKey == 'num') {
                 
                    return rowIndex + 1; // Auto-incrémenter le numéro de ligne
                }else{
                  return row[col.dataKey]; // Accéder aux données avec le champ de la colonne

                }
            }));


            // Génération du tableau dans le PDF
            doc.autoTable(header, data, tableConfig);

            // Enregistrement du PDF
            doc.save("requêtes.pdf");
        });
    });
};
  
  
const exportExcel = () => {
  import('xlsx').then((xlsx) => {
      // Créer les entêtes à partir de colsExport
      const headers = colsExpo.map(col => col.header);
      const fields = colsExpo.map(col => col.field);

      // Créer les données formatées
      const data = products.map((item, index) => {
          let rowData = {};
          fields.forEach((field, i) => {
              if (field === 'num') {
                  rowData[headers[i]] = index + 1; // Numéro auto-incrémenté
              } else {
                  rowData[headers[i]] = item[field];
              }
          });
          return rowData;
      });

      // Ajouter les entêtes comme première ligne des données
      const worksheetData = [headers, ...data.map(Object.values)];

      // Créer la feuille de calcul à partir des données formatées
      const worksheet = xlsx.utils.aoa_to_sheet(worksheetData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // Générer le buffer Excel
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Sauvegarder le fichier Excel
      saveAsExcelFile(excelBuffer, 'plainte');
  });
};

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };


  const getStatusClassName = (status) => {
    switch (status) {
      case 1:
        return "bg-danger";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-primary";
      case 4:
        return "bg-secondary";
      case 5:
        return "bg-info";
      case 6:
        return "bg-dark";
      case 7:
        return "bg-success";
      case 8:
        return "bg-secondary";
      case 9:
        return "bg-dark";
      case 10:
        return "bg-info";
      case 11:
        return "bg-primary";
      default:
        return "bg-success";
    }
  };


  const getLabel = (status) => {
    switch (status) {
      case 1:
        return "Plainte Soumise";
      case 2:
        return "Plainte Réceptionnée";
      case 3:
        return "Plainte Classée";
      case 4:
        return "En Complétude D'information";
      case 5:
        return "Plainte Instruite";
      case 6:
        return "Plainte En Investigation";
      case 7:
        return "Plainte En Résultat D'investigation";
      case 8:
        return "Plainte En Rapport D'investigation";
      case 9:
        return "Plainte En Avis de Règlement";
      case 10:
        return "Plainte Traitée";
      case 11:
        return "Plainte Traitée";
      default:
        return "Plainte Classée";
    }
  };
  const seeButtonArchive = (status) => {
    switch (status) {
    
      case 3:
        return 1;
      case 10:
        return 1;
      default:
        return 0;
    }
  };
  const filterDate = (debut, fin)=>{
    
    const startDate = moment(debut, "YYYY-MM-DD");
    const endDate = moment(fin, "YYYY-MM-DD");


    if (startDate > endDate) {
      Swal.fire({
        title: "Attention!",
        text: "La date de fin doit être supérieur au égal à la date de début",
        icon: "error",
      });
    } else {

      const resultat = intermediaire.filter(item => {
        const requeteDate = moment(item.dt, "DD/MM/YYYY");
        return requeteDate.isBetween(startDate, endDate, undefined, '[]'); // '[]' pour inclure les dates limites
      });
  setProducts(resultat)
     
    } 
   
  }

  const initChoixDate = ()=>{
    setProducts(intermediaire)
    setDate_debut("")
    setDate_fin("")
  }

  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.cd);
    const label = getLabel(rowData.cd);

    return <span className={`badge ${statusClassName}`}>{label}</span>;
  };

  const header = (
    <div className="container-fluid">

    <div className="row">
<div className="col-6">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="col-6 text-end">
      <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation PDF.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className="ml-2"
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="danger"
        />
        </OverlayTrigger>
        <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Excel.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file-excel"
          className="ml-2"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
        />
           </OverlayTrigger>
           <OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Exportation Csv.
                    </Tol>
                  }
                >
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className="ml-2"
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          severity="secondary"
        />
           </OverlayTrigger>
      </div>
    </div>

    <div className="row pt-2">
      <div className="col">
      <Form.Label htmlFor="dd">
              Date de début
            </Form.Label>
      </div>
      <div className="col">
      <Form.Label htmlFor="df">
              Date de fin
            </Form.Label>
      </div>
      <div className="col">
     
      </div>
      <div className="col">
     
      </div>
    </div>
    <div className="row">
      <div className="col">
      <Form.Control
              type="date"
              id="dd"
              value={date_debut}
              onChange={(v)=>setDate_debut(v.target.value)}

              style={{
                border: "1px solid #1f365c",
                boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                borderRadius: "5px",
              }}
            />
      </div>
      <div className="col">
      <Form.Control
              type="date"
              id="df"
              value={date_fin}
              onChange={(v)=>setDate_fin(v.target.value)}
              style={{
                border: "1px solid #1f365c",
                boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                borderRadius: "5px",
              }}
            />
      </div>
      <div className="col d-flex flex-row justify-content-start gap-2">
      <Btn variant="secondary" disabled={date_debut == "" || date_fin == ""}
      onClick={()=>filterDate(date_debut, date_fin)}
      >
            Chercher
          </Btn>
      <Btn variant="danger"
      onClick={()=>initChoixDate()}
      >
            Annuler
          </Btn>
      </div>
      <div className="col">
     
      </div>
    </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">

<OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Détails plainte.
                    </Tol>
                  }
                >
  
    <a onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{ borderRadius: 30, cursor: "pointer" }}>
      <i class="ri-eye-line  ri-2x"></i><span>
        </span>
        </a>
        </OverlayTrigger>
        
{seeButtonArchive(rowData?.id_stat_plt) ==1 &&<OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Archiver
                    </Tol>
                  }
                >
        <a onClick={() => handleDelete(rowData)} class="badge bg-danger p-2 " style={{ borderRadius: 30 , cursor: "pointer"}}><i class="ri-archive-line  ri-2x"></i><span></span></a>
        </OverlayTrigger>}
    
      </div>
    );
  };

  const handleEdit = (rowData) => {
    const clickedCode = rowData.code;
    const selectedComplaint = plaintes.liste.find(
      (complaint) => complaint.num_suivi_plt === clickedCode
    );

    if (selectedComplaint) {
      props.handleshow(selectedComplaint)

    }

  };

  const handleDelete = (rowData) => {
    props.handleShow1()
  };

  const rowClassName = (rowData, index) => {
    return index !== products.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />

      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={products}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={7}
        rowClassName={rowClassName} // Applique la classe spécifiée aux lignes
      >
        {cols.map((col, index) => {
          if (col.field === "st") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={statusBodyTemplate}
              />
            );
          } else if (col.field === "actions") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={actionBodyTemplate}
              />
            );
          } else {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
              />
            );
          }
        })}
      </DataTable>
    </div>
  );
}
